<details class="govuk-details">
  <summary class="govuk-details__summary">
    <span class="govuk-details__summary-text">
      {{ title }}
    </span>
  </summary>
  <div class="govuk-details__text">
    <ng-content></ng-content>
  </div>
</details>
