<app-training-link-panel
  data-testid="trainingLinkPanel"
  [workplace]="workplace"
  [workers]="workers"
  [totalRecords]="totalRecords"
  [tAndQsLastUpdated]="tAndQsLastUpdated"
></app-training-link-panel>
<ng-container *ngIf="workers && workers.length; else noStaffRecords">
  <div class="govuk-grid-row" *ngIf="totalRecords === 0" data-testid="noTandQRecords">
    <div class="govuk-grid-column-two-thirds">
      <p>
        You've not added any training or qualification records yet. Many care providers store their staff training and
        qualification records in ASC-WDS and get alerts when training is about to expire.
      </p>
    </div>
  </div>
  <app-training-info-panel
    *ngIf="workers"
    data-testid="trainingInfoPanel"
    [workplaceUid]="workplace.uid"
    [totalExpiredTraining]="totalExpiredTraining"
    [totalExpiringTraining]="totalExpiringTraining"
    [totalStaffMissingMandatoryTraining]="staffMissingMandatoryTraining"
    data-cy="total-training-panel"
  ></app-training-info-panel>

  <app-training-select-view-panel
    [viewTrainingByCategory]="viewTrainingByCategory"
    (handleViewToggle)="handleViewTrainingByCategory($event)"
  >
  </app-training-select-view-panel>
  <app-training-and-qualifications-summary
    *ngIf="!viewTrainingByCategory"
    data-testid="trainingAndQualsSummary"
    [workplace]="workplace"
    [workers]="workers"
    [workerCount]="workerCount"
    [totalRecords]="totalRecords"
    [sortByValue]="staffSortByValue"
    (changeStaffSortBy)="updateSortByValue($event)"
    data-cy="training-summary"
  ></app-training-and-qualifications-summary>

  <app-training-and-qualifications-categories
    *ngIf="viewTrainingByCategory"
    data-testid="trainingAndQualsCategories"
    [workplace]="workplace"
    [trainingCategories]="trainingCategories"
    [totalTraining]="totalTraining"
    [sortByValue]="trainingSortByValue"
    (changeTrainingSortBy)="updateSortByValue($event)"
  ></app-training-and-qualifications-categories>
</ng-container>
<ng-template #noStaffRecords>
  <app-inset-text data-testid="noStaffRecordsWarningBanner" color="warning" [removeTopMargin]="true"
    >You need to <a href="#" (click)="navigateToStaffRecords($event)">add staff records</a> before you start to add
    training and qualifications</app-inset-text
  >
</ng-template>
