<div class="govuk-grid-row">
  <div class="govuk-grid-column-two-thirds-from-desktop">
    <h1 class="govuk-heading-xl">Reset link sent</h1>
    <p>If there's an ASC-WDS account for {{ emailAddress }}, you'll receive an email with a reset password link.</p>
    <p>
      The email should be in your inbox soon, if not, check your spam folder before you
      <a [routerLink]="['/contact-us']">contact us</a> for help.
    </p>

    <a [routerLink]="['/login']" role="button" draggable="false" class="govuk-button govuk-!-margin-top-4">
      Return to sign in
    </a>
  </div>
</div>
