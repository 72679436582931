<div class="govuk-grid-row govuk-!-margin-top-3">
  <div class="govuk-grid-column-two-thirds">
    <ng-container *ngIf="workplace && !recruitmentJourneyExistingUserBanner && !addWorkplaceDetailsBanner">
      <app-inset-text *ngIf="workplace.employerType" [color]="'todo'" class="govuk-!-margin-bottom-2">
        <div class="govuk-grid-row">
          <div class="govuk-grid-column-full">
            <h2 class="govuk-heading-s">We've added some questions to ASC-WDS</h2>
            <p>
              Skills for Care are working with the Department of Health and Social Care to help them better understand
              how difficult it is to recruit and keep people working in the adult social care sector
            </p>
            <a href="#" (click)="canEditEstablishment && setRecuritmentBannerToTrue($event)">
              Answer our staff recruitment and retention questions
            </a>
          </div>
        </div>
      </app-inset-text>
    </ng-container>

    <ng-container *ngIf="workplace && canEditEstablishment">
      <app-inset-text *ngIf="addWorkplaceDetailsBanner" [color]="'todo'">
        <div class="govuk-grid-row">
          <div class="govuk-grid-column-full">
            <a [routerLink]="['/workplace', workplace?.uid, 'start']">Start to add more details about your workplace</a>
          </div>
        </div>
      </app-inset-text>
    </ng-container>
    <ng-container
      *ngIf="
        workplace &&
        canAddWorker &&
        workerCount !== workplace.numberOfStaff &&
        workerCount > 0 &&
        convertToDate(workplace.eightWeeksFromFirstLogin) < now
      "
    >
      <app-staff-mismatch-banner
        (selectStaffTab)="selectStaffTab($event)"
        (selectTotalStaff)="selectTotalStaff($event)"
        [workerCount]="workerCount"
        [numberOfStaff]="workplace.numberOfStaff"
      ></app-staff-mismatch-banner>
    </ng-container>

    <div *ngIf="workplace" data-cy="main-home-links">
      <div>
        <h3 class="govuk-heading-m govuk-!-font-weight-regular govuk-!-margin-bottom-0">
          <a [routerLink]="['/benefits-bundle']">
            View the ASC-WDS Benefits Bundle
            <p class="govuk-tag govuk-tag--blue govuk-!-margin-left-4 asc-tag">NEW</p></a
          >
        </h3>
        <p>Access all your special offers and discounts across Skills for Care's product range.</p>
      </div>
      <div *ngIf="canViewReports && !wdfNewDesignFlag">
        <h3 class="govuk-heading-m govuk-!-font-weight-regular govuk-!-margin-bottom-0">
          <a [routerLink]="['/wdf']"> Check your WDF data </a>
        </h3>
        <p class="govuk-!-margin-top-4">Check if your data meets Workforce Development Fund (WDF) requirements.</p>
      </div>
      <div *ngIf="canBulkUpload">
        <h3 class="govuk-heading-m govuk-!-font-weight-regular govuk-!-margin-bottom-0">
          <a [routerLink]="['/bulk-upload']" (click)="setReturn()"> Bulk upload your data </a>
        </h3>
        <p class="govuk-!-margin-top-4">
          Use bulk upload to add large amounts of staff, workplace, and training data. Bulk uploads may not be suitable
          for smaller workplaces.
        </p>
      </div>
    </div>
  </div>
  <div *ngIf="workplace" class="govuk-grid-column-one-third" data-cy="home-other-links">
    <app-article-list></app-article-list>
    <h3 class="govuk-heading-s govuk-!-margin-top-1">Other links</h3>
    <ul class="govuk-list govuk-!-margin-bottom-6">
      <li class="govuk-!-margin-bottom-3">
        <a [routerLink]="['/asc-wds-certificate']"
          ><p class="govuk-tag govuk-tag--blue govuk-!-margin-right-8 asc-tag" style="float: right">NEW</p>
          Get your ASC-WDS certificate</a
        >
      </li>
      <li class="govuk-!-margin-bottom-3">
        <a [routerLink]="['/about-ascwds']">About ASC-WDS</a>
      </li>
      <li *ngIf="canViewWorkplaces" class="govuk-!-margin-bottom-3">
        <a [routerLink]="['/workplace', 'view-all-workplaces']" data-cy="view-all-workplaces">View all workplaces</a>
      </li>
      <li *ngIf="canViewChangeDataOwner" class="govuk-!-margin-bottom-3">
        <ng-container *ngIf="isOwnershipRequested; else changeDataOwner">
          <a (click)="cancelChangeDataOwnerRequest($event)" href="#">Data request pending</a>
        </ng-container>
        <ng-template #changeDataOwner>
          <a (click)="onChangeDataOwner($event)" href="#">Change data owner</a>
        </ng-template>
      </li>
      <li *ngIf="canViewDataPermissionsLink" class="govuk-!-margin-bottom-3">
        <a (click)="setDataPermissions($event)" href="#">Set data permissions</a>
      </li>
      <li *ngIf="canLinkToParent && !workplace.isParent" class="govuk-!-margin-bottom-3">
        <ng-container *ngIf="linkToParentRequestedStatus; else requestLinkToParent">
          <a (click)="cancelLinkToParent($event)" href="#">Link to my parent organisation pending</a>
        </ng-container>
        <ng-template #requestLinkToParent>
          <a (click)="linkToParent($event)" href="#">Link to my parent organisation</a>
        </ng-template>
      </li>
      <li *ngIf="canRemoveParentAssociation" class="govuk-!-margin-bottom-3">
        <a (click)="removeLinkToParent($event)" href="#">Remove link to my parent organisation</a>
      </li>
      <li
        *ngIf="canBecomeAParent && !linkToParentRequestedStatus && !workplace.isParent"
        class="govuk-!-margin-bottom-3"
      >
        <ng-container *ngIf="parentStatusRequested; else requestBecomeAParent">
          <a (click)="cancelBecomeAParent($event)" href="#">Parent request pending</a>
        </ng-container>
        <ng-template #requestBecomeAParent>
          <a (click)="becomeAParent($event)" href="#">Become a parent organisation</a>
        </ng-template>
      </li>
      <li class="govuk-!-margin-bottom-3">
        <a [routerLink]="['/first-login-wizard']">Help to get you started</a>
      </li>
    </ul>
    <div *ngIf="canRunLocalAuthorityReport && workplace" data-cy="download-report">
      <h3 class="govuk-heading-s govuk-!-margin-top-1">Download reports</h3>
      <ul *ngIf="workplace" class="govuk-list">
        <li>
          <a
            class="govuk__flex govuk__align-items-center"
            href="/download/{{ now | date: 'yyyy-MM-dd' }}-sfc-local-authority-report.xls'"
            (click)="downloadLocalAuthorityReport($event)"
          >
            <img alt="" src="/assets/images/icon-download.svg" />
            <span class="govuk-!-margin-left-1">Local authority progress</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
