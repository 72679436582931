<div class="govuk-grid-row">
  <div class="govuk-grid-column-two-thirds">
    <h1 class="govuk-heading-l" data-cy="admin-heading">Admin</h1>
  </div>
</div>
<div class="govuk-grid-row">
  <div class="govuk-grid-column-one-quarter-from-desktop">
    <app-admin-menu></app-admin-menu>
  </div>
  <div class="govuk-grid-column-three-quarters-from-desktop govuk-!-padding-top-2">
    <router-outlet></router-outlet>
  </div>
</div>
