<div class="govuk-grid-row govuk-!-margin-left-0">
  <div class="govuk-grid-column-two-thirds">
    <div class="govuk-grid-row govuk-!-margin-bottom-4">
      <h1 class="govuk-heading-l">Get your ASC-WDS certificate</h1>
      <p>
        As an active ASC-WDS user, you should be proud that the data you share makes the adult social care sector better
        for everybody.
      </p>
    </div>
    <div class="govuk-grid-row govuk-!-margin-bottom-8" data-testid="certificateDownload">
      <p>
        This certificate recognises that you contribute information used by DHSC, the Care Quality Commission, and local
        authorities.
      </p>

      <img
        alt="An ASC-WDS certificate with sample data"
        src="/public/download/certificates/sample-image/asc-wds+certificate+sample+{{ years }}.png"
        width="300px"
        style="border: 1px solid #b1b4b6"
        class="govuk-!-margin-bottom-4"
      />
      <a
        class="govuk-list govuk-list--inline govuk__flex govuk__align-items-center govuk-button--link govuk-link"
        (click)="downloadCertificate()"
        target="_blank"
        href="#"
        onclick="return false;"
      >
        <img alt="" src="/assets/images/icon-download.svg" /><span class="govuk-!-margin-left-1"
          >Download your certificate (PDF, 90KB)</span
        >
      </a>
    </div>
    <div class="govuk-grid-row govuk-!-margin-bottom-8" data-testid="footerDownload">
      <h2 class="govuk-heading-s">ASC-WDS email signature</h2>
      <p>Our unique email signature can be used to let everyone know that you're helping to spread the message.</p>

      <img
        alt="An ASC-WDS email footer"
        src="public/download/certificates/footer/ASC-WDS_Footer+{{ years }}.jpg"
        width="300px"
        style="border: 1px solid #b1b4b6"
        class="govuk-!-margin-bottom-4"
      />
      <a
        class="govuk-list govuk-list--inline govuk__flex govuk__align-items-center"
        href="public/download/certificates/footer/ASC-WDS_Footer+{{ years }}.jpg"
        target="_blank"
        ><img alt="" src="/assets/images/icon-download.svg" /><span class="govuk-!-margin-left-1"
          >Download your email signature (JPG, 96KB)</span
        ></a
      >
    </div>
    <div class="govuk-grid-row govuk-!-margin-bottom-8" data-testId="logoDownload">
      <h2 class="govuk-heading-s">ASC-WDS logo</h2>
      <p>
        If you want something a bit smaller or something to use on your web pages, then we also have an ASC-WDS logo.
      </p>

      <img
        alt="ASC-WDS logo"
        src="public/download/certificates/logo/ASC-WDS+logo_20{{ years }}.jpg"
        width="150px"
        style="border: 1px solid #b1b4b6"
        class="govuk-!-margin-bottom-4"
      />
      <a
        class="govuk-list govuk-list--inline govuk__flex govuk__align-items-center"
        href="public/download/certificates/logo/ASC-WDS+logo_20{{ years }}.jpg"
        target="_blank"
        ><img alt="" src="/assets/images/icon-download.svg" /><span class="govuk-!-margin-left-1"
          >Download your logo (JPG, 145KB)</span
        ></a
      >
    </div>

    <div class="govuk-grid-row">
      <a role="button" draggable="false" class="govuk-button" [routerLink]="['/dashboard']" data-testid="returnButton">
        Return to home
      </a>
    </div>
  </div>
</div>
