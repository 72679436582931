<app-dashboard-header></app-dashboard-header>

<div class="govuk-grid-row">
  <div class="govuk-grid-column-full">
    <app-tabs (selectedTabClick)="tabClickEvent($event)">
      <app-tab [title]="'Home'">
        <app-home-tab [workplace]="workplace" [workerCount]="workerCount" data-testid="homeTab"></app-home-tab>
      </app-tab>

      <app-tab
        *ngIf="workplace && canViewEstablishment"
        [title]="'Workplace'"
        [redAlert]="workplace?.employerType && (showCQCDetailsBanner || showSharingPermissionsBanner)"
      >
        <app-workplace-tab [workplace]="workplace" [workerCount]="workerCount"></app-workplace-tab>
      </app-tab>

      <app-tab *ngIf="workplace && canViewListOfWorkers" [alert]="totalStaffRecords === 0" [title]="'Staff records'">
        <app-staff-records-tab
          [workplace]="workplace"
          [workers]="workers"
          [workerCount]="workerCount"
        ></app-staff-records-tab>
      </app-tab>

      <app-tab
        *ngIf="workplace && canViewListOfWorkers"
        [alert]="trainingAlert === 1"
        [redAlert]="trainingAlert === 2"
        [title]="'Training and qualifications'"
      >
        <app-training-and-qualifications-tab
          [workplace]="workplace"
          [workers]="workers"
          [workerCount]="workerCount"
          [trainingCounts]="trainingCounts"
          [tAndQsLastUpdated]="tAndQsLastUpdated"
        ></app-training-and-qualifications-tab>
      </app-tab>

      <app-tab *ngIf="workplace" [title]="'Benchmarks'">
        <ng-container *ngIf="newDataAreaFlag && canSeeNewDataArea; else benchmarksTab">
          <div class="govuk-grid-row">
            <div class="govuk-grid-column-one-half">
              <h2 class="govuk-heading-m govuk-!-margin-bottom-5 govuk-!-font-size-27">Benchmarks</h2>
            </div>
          </div>
          <app-data-area-tab [workplace]="workplace" data-testid="data-area-tab"></app-data-area-tab>
        </ng-container>
        <ng-template #benchmarksTab>
          <app-benchmarks-tab
            class="asc-benchmarks-tab"
            [workplace]="workplace"
            data-testid="benchmarks-tab"
          ></app-benchmarks-tab>
        </ng-template>
      </app-tab>

      <app-tab *ngIf="workplace && canViewEstablishment && wdfNewDesignFlag" [title]="'WDF'">
        <app-wdf-tab [workplace]="workplace"></app-wdf-tab>
      </app-tab>
    </app-tabs>
  </div>
</div>
