<ng-container *ngIf="breadcrumbs">
  <div class="govuk-breadcrumbs govuk-!-margin-top-0 govuk-!-padding-top-3">
    {{ workplaceName ? workplaceName : 'Go back to' }}:
    <ol class="govuk-breadcrumbs__list">
      <li
        class="govuk-breadcrumbs__list-item"
        *ngFor="let breadcrumb of breadcrumbs; let i = index"
        [attr.aria-current]="i + 1 === breadcrumbs.length ? 'page' : null"
      >
        <ng-container *ngIf="i + 1 !== breadcrumbs.length; else final">
          <a
            class="govuk-breadcrumbs__link"
            (click)="selectTab($event, { path: breadcrumb.path, fragment: breadcrumb.fragment })"
            [routerLink]="breadcrumb.path"
            [fragment]="breadcrumb.fragment"
            >{{ breadcrumb.title }}</a
          >
        </ng-container>
        <ng-template #final>
          {{ breadcrumb.title }}
        </ng-template>
      </li>
    </ol>
  </div>
</ng-container>
