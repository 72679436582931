<div class="govuk-form-group">
  <fieldset class="govuk-fieldset">
    <legend class="govuk-fieldset__legend govuk-!-font-weight-bold">Comparison groups</legend>
    <ng-container *ngIf="comparisonDataExists; else noComparisonDataAvailable">
      <div class="govuk-radios govuk-!-margin-top-4" data-module="govuk-radios">
        <div class="govuk-radios__item">
          <input
            data-testid="main-service-input"
            class="govuk-radios__input"
            id="comparison-groups"
            name="comparison-groups"
            type="radio"
            value="mainService"
            [checked]="!viewBenchmarksComparisonGroups"
            (change)="handleViewChange($event)"
          />
          <label class="govuk-label govuk-radios__label" for="comparison-groups">
            {{ mainServiceName | serviceName }} providers in {{ localAuthorityLocation | formatAmpersand }}
          </label>
        </div>
        <div class="govuk-radios__item">
          <input
            data-testid="good-and-outstanding-input"
            class="govuk-radios__input"
            id="comparison-groups-good-and-outstanding"
            name="comparison-groups"
            type="radio"
            value="GoodAndOutstanding"
            [checked]="viewBenchmarksComparisonGroups"
            (change)="handleViewChange($event)"
          />
          <label class="govuk-label govuk-radios__label" for="comparison-groups-good-and-outstanding">
            Good and outstanding CQC providers in {{ localAuthorityLocation | formatAmpersand }}
          </label>
        </div>
      </div>
    </ng-container>
    <ng-template #noComparisonDataAvailable>
      <p class="govuk-body govuk-!-margin-top-2 govuk-!-margin-bottom-4" data-testid="no-comparison-data">
        We do not have enough comparison group data to compare your workplace against.
      </p>
    </ng-template>
  </fieldset>
</div>
