<ng-container [ngSwitch]="selectedTab">
  <ng-container *ngSwitchCase="'home'">
    <ng-container *ngIf="!isParent; else parent">
      <app-new-home-tab [workplace]="workplace" [meta]="tilesData?.meta" data-testid="home-tab"></app-new-home-tab>
    </ng-container>
    <ng-template #parent>
      <app-parent-home-tab
        [workplace]="workplace"
        [meta]="tilesData?.meta"
        data-testid="parentHomeTab"
      ></app-parent-home-tab>
    </ng-template>
  </ng-container>

  <ng-container *ngSwitchCase="'workplace'">
    <app-new-workplace-tab
      *ngIf="canViewEstablishment && workplace"
      [workplace]="workplace"
      [workerCount]="workerCount"
      data-testid="workplace-tab"
    ></app-new-workplace-tab>
  </ng-container>

  <ng-container *ngSwitchCase="'staff-records'">
    <app-new-staff-tab
      *ngIf="canViewListOfWorkers && workplace"
      [workplace]="workplace"
      [workers]="workers"
      [workerCount]="workerCount"
      [staffLastUpdated]="staffLastUpdatedDate"
      data-testid="staff-records-tab"
    ></app-new-staff-tab>
  </ng-container>

  <ng-container *ngSwitchCase="'training-and-qualifications'">
    <app-new-training-tab
      *ngIf="canViewListOfWorkers && workplace"
      [workplace]="workplace"
      [workers]="workers"
      [workerCount]="workerCount"
      [trainingCounts]="trainingCounts"
      [tAndQsLastUpdated]="tAndQsLastUpdated"
      data-testid="training-and-qualifications-tab"
    ></app-new-training-tab>
  </ng-container>

  <ng-container *ngSwitchCase="'benchmarks'">
    <ng-container *ngIf="newDataAreaFlag && canSeeNewDataArea; else benchmarksTab">
      <app-data-area-tab [workplace]="workplace" [newDashboard]="true" data-testid="data-area-tab"></app-data-area-tab>
    </ng-container>
    <ng-template #benchmarksTab>
      <app-new-benchmarks-tab
        class="asc-benchmarks-tab"
        [workplace]="workplace"
        [isParentViewingSubsidiary]="false"
        data-testid="benchmarks-tab"
      ></app-new-benchmarks-tab>
    </ng-template>
  </ng-container>
</ng-container>
