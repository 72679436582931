<div
  class="govuk-inset-text"
  [ngClass]="[
    color,
    removeBottomMargin ? 'govuk-!-margin-bottom-0' : '',
    removeTopMargin ? 'govuk-!-margin-top-0' : ''
  ]"
>
  <ng-content></ng-content>
  <a *ngIf="closable" href="#" (click)="close($event)" class="govuk-util__float-right">Close</a>

  <ng-container *ngIf="linkTextForAlert">
    <ng-container *ngIf="noFloatRight; else floatRight">
      <a class="inset_link" href="#" (click)="alertLinkClick($event)" data-testid="noFloatRight"
        >{{ linkTextForAlert }}
      </a>
    </ng-container>
    <ng-template #floatRight
      ><a href="#" class="govuk-util__float-right" (click)="alertLinkClick($event)" data-testid="floatRight">{{
        linkTextForAlert
      }}</a></ng-template
    >
  </ng-container>
</div>
