<app-error-summary
  *ngIf="submitted && form.invalid"
  [formErrorsMap]="formErrorsMap"
  [serverError]="serverError"
  [form]="form"
>
</app-error-summary>

<div class="govuk-grid-row">
  <div class="govuk-grid-column-two-thirds-from-desktop">
    <form #formEl novalidate (ngSubmit)="onSubmit()" [formGroup]="form" id="server-error">
      <fieldset class="govuk-fieldset">
        <legend class="govuk-fieldset__legend govuk-fieldset__legend--xl">
          <h1 class="govuk-fieldset__heading">Forgot your password?</h1>
        </legend>

        <p>Enter your username or email address and we will send you a link to reset your password.</p>

        <div
          class="govuk-form-group govuk-!-margin-bottom-7"
          [class.govuk-form-group--error]="form.get('usernameOrEmail').errors && submitted"
        >
          <label class="govuk-label" for="usernameOrEmail"> Username or email address </label>
          <span
            id="usernameOrEmail-error"
            class="govuk-error-message"
            *ngIf="form.get('usernameOrEmail').errors?.required && submitted"
          >
            <span class="govuk-visually-hidden">Error:</span> {{ getFormErrorMessage('usernameOrEmail', 'required') }}
          </span>
          <input
            class="govuk-input govuk-!-width-full"
            [class.govuk-input--error]="form.get('usernameOrEmail').errors && submitted"
            id="usernameOrEmail"
            name="usernameOrEmail"
            type="text"
            [formControlName]="'usernameOrEmail'"
          />
        </div>
      </fieldset>

      <button type="submit" class="govuk-button">Send reset link</button>
    </form>
    <p><a [routerLink]="['/login']">Back to sign in</a></p>
  </div>
</div>
