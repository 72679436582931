<div class="govuk-grid-row">
  <div class="govuk-grid-column-one-half">
    <h2 class="govuk-heading-m govuk-!-margin-bottom-3">Benchmarks</h2>
  </div>
</div>
<div class="govuk-grid-row">
  <app-comparison-group-header
    #comparisonGroupText
    [canViewFullContent]="canViewFullBenchmarks"
    [meta]="tilesData?.meta"
    [workplaceID]="workplace.uid"
  ></app-comparison-group-header>
</div>

<div id="benchmarks-tiles" data-cy="benchmarks-tiles">
  <div class="govuk-grid-row govuk-!-margin-bottom-3 govuk-!-margin-top-3">
    <div class="govuk-grid-column-one-half">
      <a
        class="asc-font-19"
        data-html2canvas-ignore
        [routerLink]="['/workplace', workplace.uid, 'benchmarks', 'about-the-data']"
        (click)="setReturn()"
        >About the data</a
      >
    </div>
    <div class="govuk-grid-column-one-half">
      <a
        class="govuk-list govuk-list--inline govuk-list govuk__flex govuk-util__align-right govuk-!-padding-left-8"
        role="button"
        draggable="false"
        href="/benchmarks.pdf"
        data-html2canvas-ignore
        data-testid="download-button"
        (click)="downloadAsPDF($event)"
      >
        <img alt="" src="/assets/images/icon-download.svg" /><span class="govuk-!-margin-left-1">
          Download Benchmarks PDF (89KB, 2 pages)
        </span>
      </a>
    </div>
  </div>

  <div class="govuk-grid-row">
    <app-benchmark-tile
      [canViewFullContent]="canViewFullBenchmarks"
      [content]="payContent"
      [tile]="payTile"
      [workplaceID]="workplace.uid"
    >
    </app-benchmark-tile>
    <app-benchmark-tile
      [canViewFullContent]="canViewFullBenchmarks"
      [content]="turnoverContent"
      [tile]="turnoverTile"
      [workplaceID]="workplace.uid"
    >
    </app-benchmark-tile>
    <app-benchmark-tile
      [canViewFullContent]="canViewFullBenchmarks"
      [content]="sicknessContent"
      [tile]="sicknessTile"
      [workplaceID]="workplace.uid"
    >
    </app-benchmark-tile>
    <app-benchmark-tile
      [canViewFullContent]="canViewFullBenchmarks"
      [content]="qualificationsContent"
      [tile]="qualificationsTile"
      [workplaceID]="workplace.uid"
    >
    </app-benchmark-tile>
  </div>
  <app-benchmarks-about-the-data #aboutData class="hidden" [workplace]="workplace"></app-benchmarks-about-the-data>
</div>
