<fieldset class="govuk-fieldset">
  <legend class="govuk-fieldset__legend govuk-fieldset__legend--l">
    <span class="govuk-caption-l">Workplace</span>
    <h1 class="govuk-fieldset__heading">Add the total number of staff for your workplace</h1>
  </legend>
  <p data-testid="info">
    The number should include all staff (care-providing and none care-providing, those on permanent or temporary
    contracts, pool, bank and agency workers).
  </p>
  <app-details [title]="'Why we ask for this information'" data-testid="totalStaffRevealTitle"
    ><p data-testid="totalStaffRevealText">
      We need to know the total number of staff employed in workplaces so that we can calculate how many people work in
      the sector. We also use it to work out turnover and vacancy rates, staff to service ratios and to see if the
      sector is growing.
    </p></app-details
  >
  <div class="govuk-form-group" [formGroup]="form" [class.govuk-form-group--error]="submitted && form.invalid">
    <label class="govuk-label" for="totalStaff"> Number of staff</label>
    <span *ngIf="submitted && form.get('totalStaff').invalid" id="totalStaff-error" class="govuk-error-message">
      <span class="govuk-visually-hidden">Error:</span> {{ getErrorMessage('totalStaff') }}
    </span>

    <input
      class="govuk-input govuk-input--width-5"
      [formControlName]="'totalStaff'"
      id="totalStaff"
      name="totalStaff"
      [class.govuk-input--error]="submitted && form.get('totalStaff').invalid"
      type="text"
      aria-describedby="totalStaff-hint"
    />
  </div>
</fieldset>
