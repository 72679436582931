<app-new-dashboard-header tab="home" [workplace]="workplace"></app-new-dashboard-header>
<div class="govuk-width-container govuk-!-margin-top-7">
  <app-alert></app-alert>
  <app-summary-section
    [canViewListOfWorkers]="canViewListOfWorkers"
    [workplace]="workplace"
    [workerCount]="workerCount"
    [workersCreatedDate]="workersCreatedDate"
    [trainingCounts]="trainingCounts"
    [workersNotCompleted]="workersNotCompleted"
    [(navigateToTab)]="navigateToTab"
    [canViewEstablishment]="canViewEstablishment"
    [noOfWorkersWhoRequireInternationalRecruitment]="noOfWorkersWhoRequireInternationalRecruitment"
  ></app-summary-section>
</div>
<div class="govuk-width-container govuk-!-margin-top-7">
  <div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
      <div class="govuk-grid-row">
        <div *ngIf="canViewReports" class="govuk-grid-column-one-half govuk-!-padding-right-1">
          <app-card [image]="'/assets/images/wdf.svg'">
            <a
              class="govuk-link--no-visited-state govuk-!-font-size-19 govuk-!-font-weight-bold"
              routerLink="/wdf"
              href="#"
              >Does your data meet funding requirements?</a
            >
            <p class="govuk-!-margin-top-5">
              Update your data so that you can claim money back against the costs of training your staff.
            </p></app-card
          >
        </div>

        <div class="govuk-grid-column-one-half govuk-!-padding-left-1">
          <app-card [image]="'/assets/images/benchmarks.svg'">
            <a
              (click)="navigateToTab($event, 'benchmarks')"
              class="govuk-link--no-visited-state govuk-!-font-size-19 govuk-!-font-weight-bold"
              href="#"
            >
              <ng-container *ngIf="bigThreeServices && hasBenchmarkComparisonData; else notBigThreeServices">
                See how your pay, recruitment and retention compares against other workplaces</ng-container
              >
              <ng-template #notBigThreeServices> See how you compare against other workplaces</ng-template>
            </a>
            <p class="govuk-!-margin-top-5">
              {{ benchmarksMessage }}
            </p>
          </app-card>
        </div>
      </div>
    </div>
    <div class="govuk-grid-column-one-third">
      <app-new-article-list data-testid="article-list"></app-new-article-list>
      <div class="govuk-!-margin-top-7" data-cy="home-other-links">
        <h3 class="govuk-heading-s">Other links</h3>
        <ul class="govuk-list govuk-!-margin-bottom-6">
          <app-link-with-arrow>
            <a class="govuk-link" [routerLink]="['/asc-wds-certificate']"
              ><p class="govuk-tag govuk-tag--blue govuk-!-margin-right-6 asc-tag" style="float: right">NEW</p>
              Get your ASC-WDS certificate</a
            >
          </app-link-with-arrow>

          <app-link-with-arrow *ngIf="canBulkUpload">
            <a class="govuk-link" [routerLink]="['/bulk-upload']">Bulk upload your data</a>
          </app-link-with-arrow>

          <app-link-with-arrow>
            <a class="govuk-link" [routerLink]="['/benefits-bundle']">View the ASC-WDS Benefits Bundle</a>
          </app-link-with-arrow>

          <app-link-with-arrow>
            <a class="govuk-link" [routerLink]="['/about-ascwds']">About ASC-WDS</a>
          </app-link-with-arrow>

          <app-link-with-arrow *ngIf="canLinkToParent && !workplace.isParent">
            <ng-container *ngIf="linkToParentRequestedStatus; else requestLinkToParent">
              <ng-container *ngIf="newHomeDesignParentFlag; else dialogLinkParentRequestPending">
                <a
                  class="govuk-link"
                  [routerLink]="['/link-to-parent']"
                  [state]="{ linkToParentRequested: linkToParentRequestedStatus }"
                  >Link to a parent workplace (request pending)</a
                >
              </ng-container>
              <ng-template #dialogLinkParentRequestPending>
                <a class="govuk-link" (click)="cancelLinkToParent($event)" href="#"
                  >Link to my parent organisation pending</a
                >
              </ng-template>
            </ng-container>
            <ng-template #requestLinkToParent>
              <ng-container *ngIf="newHomeDesignParentFlag; else dialogLinkParentRequest">
                <a class="govuk-link" [routerLink]="['/link-to-parent']">Link to a parent workplace</a>
              </ng-container>
              <ng-template #dialogLinkParentRequest>
                <a class="govuk-link" (click)="linkToParent($event)" href="#">Link to my parent organisation</a>
              </ng-template>
            </ng-template>
          </app-link-with-arrow>

          <app-link-with-arrow *ngIf="canBecomeAParent && !linkToParentRequestedStatus && !workplace.isParent">
            <ng-container *ngIf="parentStatusRequested; else requestBecomeAParent">
              <ng-container *ngIf="newHomeDesignParentFlag; else dialogParentRequestPending">
                <a class="govuk-link" [routerLink]="['/become-a-parent']"
                  >Become a parent and manage other workplaces' data (request pending)</a
                >
              </ng-container>
              <ng-template #dialogParentRequestPending
                ><a class="govuk-link" (click)="cancelBecomeAParent($event)" href="#"
                  >Parent request pending</a
                ></ng-template
              >
            </ng-container>
            <ng-template #requestBecomeAParent>
              <ng-container *ngIf="newHomeDesignParentFlag; else dialogParentRequest">
                <a class="govuk-link" [routerLink]="['/become-a-parent']"
                  >Become a parent and manage other workplaces' data</a
                >
              </ng-container>
              <ng-template #dialogParentRequest
                ><a class="govuk-link" (click)="becomeAParent($event)" href="#"
                  >Become a parent organisation</a
                ></ng-template
              >
            </ng-template>
          </app-link-with-arrow>

          <app-link-with-arrow *ngIf="canViewChangeDataOwner">
            <ng-container *ngIf="isOwnershipRequested; else changeDataOwner">
              <a (click)="cancelChangeDataOwnerRequest($event)" href="#">Data request pending</a>
            </ng-container>
            <ng-template #changeDataOwner>
              <ng-container *ngIf="newHomeDesignParentFlag; else dialogChangeDataOwner">
                <a class="govuk-link" [routerLink]="['/workplace/change-data-owner']">Change data owner</a>
              </ng-container>
              <ng-template #dialogChangeDataOwner
                ><a (click)="onChangeDataOwner($event)" href="#">Change data owner</a></ng-template
              >
            </ng-template></app-link-with-arrow
          >
          <app-link-with-arrow *ngIf="canViewDataPermissionsLink">
            <a (click)="setDataPermissions($event)" href="#">Set data permissions</a>
          </app-link-with-arrow>
          <app-link-with-arrow *ngIf="canRemoveParentAssociation">
            <ng-container *ngIf="canViewChangeDataOwner; else removeLinkToParent">
              <a (click)="ownershipChangeMessage($event)" href="#">Remove the link to your parent workplace</a>
            </ng-container>
            <ng-template #removeLinkToParent>
              <a [routerLink]="['/remove-link-to-parent']">Remove the link to your parent workplace</a>
            </ng-template>
          </app-link-with-arrow>

          <app-link-with-arrow>
            <a class="govuk-link" [routerLink]="['/first-login-wizard']">Help to get you started</a>
          </app-link-with-arrow>
        </ul>
      </div>
    </div>
  </div>
</div>
