<app-error-summary
  *ngIf="(form.invalid || serverError) && submitted"
  [formErrorsMap]="formErrorsMap"
  [serverError]="serverError"
  [form]="form"
>
</app-error-summary>

<form #formEl novalidate (ngSubmit)="onSubmit()" [formGroup]="form" id="server-error">
  <fieldset class="govuk-fieldset">
    <legend class="govuk-fieldset__legend govuk-fieldset__legend--l">
      <h1 class="govuk-fieldset__heading" data-cy="signin-heading">Sign in</h1>
    </legend>

    <div
      class="govuk-form-group"
      [class.govuk-form-group--error]="(form.get('username').errors || serverError) && submitted"
    >
      <label class="govuk-label" for="username"> Username </label>
      <span
        id="username-error"
        class="govuk-error-message"
        *ngIf="form.get('username').errors && submitted"
        data-cy="username-error"
      >
        <span class="govuk-visually-hidden">Error:</span>
        {{ getFormErrorMessage('username', 'required') }}
      </span>
      <input
        data-cy="username"
        class="govuk-input govuk-input--width-20"
        [class.govuk-input--error]="(form.get('username').errors || serverError) && submitted"
        id="username"
        name="username"
        type="text"
        [formControlName]="'username'"
      />
    </div>

    <div
      class="govuk-form-group"
      [class.govuk-form-group--error]="(form.get('password').errors || serverError) && submitted"
    >
      <label class="govuk-label" for="password"> Password </label>
      <span
        id="password-error"
        class="govuk-error-message"
        *ngIf="form.get('password').errors && submitted"
        data-cy="password-error"
      >
        <span class="govuk-visually-hidden">Error:</span> {{ getFormErrorMessage('password', 'required') }}
      </span>
      <input
        data-cy="password"
        class="govuk-input govuk-input--width-20"
        [class.govuk-input--error]="(form.get('password').errors || serverError) && submitted"
        id="password"
        name="password"
        type="password"
        [formControlName]="'password'"
      />
    </div>
  </fieldset>

  <button type="submit" class="govuk-button" data-testid="signinButton">Sign in</button>
</form>

<ul class="govuk-list">
  <li><a [routerLink]="['/forgot-your-password']">Forgot your password?</a></li>
  <li><a [routerLink]="['/registration', 'create-account']" data-cy="create-account">Create an account</a></li>
</ul>
