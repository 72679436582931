<ul class="govuk-list">
  <li routerLinkActive="active">
    <a routerLink="/sfcadmin/search" class="govuk-link">Search</a>
  </li>
  <li routerLinkActive="active">
    <a routerLink="/sfcadmin/registrations" class="govuk-link">Registration requests</a>
  </li>
  <li routerLinkActive="active">
    <a routerLink="/sfcadmin/parent-requests" class="govuk-link">Parent requests</a>
  </li>
  <li routerLinkActive="active">
    <a routerLink="/sfcadmin/cqc-main-service-change" class="govuk-link">CQC main service change</a>
  </li>
  <li routerLinkActive="active">
    <a routerLink="/sfcadmin/emails" class="govuk-link">Emails</a>
  </li>
  <li routerLinkActive="active">
    <a routerLink="/sfcadmin/admin-reports" class="govuk-link">Admin reports</a>
  </li>
  <li routerLinkActive="active">
    <a routerLink="/sfcadmin/local-authorities-return" class="govuk-link">Local authority returns</a>
  </li>
  <li routerLinkActive="active">
    <a routerLink="/sfcadmin/users" class="govuk-link">Admin users</a>
  </li>
  <li routerLinkActive="active">
    <a routerLink="/sfcadmin/external-links" class="govuk-link">External links</a>
  </li>
</ul>
