<footer role="contentinfo" style="visibility: visible; display: block" #content>
  <div class="govuk-width-container">
    <span class="footer-tagline">In partnership with</span>
  </div>
  <div class="govuk__flex govuk__justify-content-space-between">
    <div class="department-of-health-logo__crest" style="border-left: 2px solid #00ad93; padding-left: 5px">
      <img
        src="/assets/images/govuk-crest.png"
        alt="Department of Health & Social Care"
        style="height: 25px; width: auto; float: left"
      />
      <span class="department-of-health-logo__name"
        ><br clear="left" />Department<br />of Health &<br />Social Care</span
      >
    </div>

    <div>Page {{ pages.current }} of {{ pages.total }}</div>
  </div>
</footer>
