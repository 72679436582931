<div class="govuk-grid-column-one-half">
  <div class="asc-tile">
    <div class="asc-tile-description">
      <h2 class="govuk-heading-l govuk-!-margin-bottom-2">
        <a
          *ngIf="canViewFullContent"
          class="asc-tile-link"
          [routerLink]="['/workplace', workplaceID, 'benchmarks', type]"
        >
          {{ title }}
        </a>
        <span *ngIf="!canViewFullContent">{{ title }}</span>
      </h2>
      <p class="govuk-body-s govuk-!-margin-bottom-0">{{ tileDescription }}</p>
    </div>
    <div class="asc-tile-key">
      <div class="govuk-grid-row">
        <div class="govuk-grid-column-full govuk-grid-column-one-half-from-desktop">
          <h3 class="govuk-body-s govuk-!-font-weight-bold govuk-!-margin-bottom-2 govuk-!-margin-top-4">
            Your workplace
          </h3>
          <p
            data-testid="yourworkplace"
            class="govuk-!-margin-bottom-0 asc-tile-key-text"
            [ngClass]="{
              'asc-color-turquoise asc-tile-key-number': workplaceHasValue,
              'govuk-body-s': !workplaceHasValue
            }"
          >
            <ng-container *ngIf="workplaceHasValue; else yourEmpty">
              <ng-container [ngSwitch]="content.type">
                <ng-container *ngSwitchCase="metrics.pay">
                  {{ workplaceValue | formatMoney }}
                </ng-container>
                <ng-container *ngSwitchCase="metrics.sickness">
                  {{ workplaceValue }}
                </ng-container>
                <ng-container *ngSwitchDefault>{{ workplaceValue | formatPercent }}</ng-container>
              </ng-container>
            </ng-container>
            <ng-template #yourEmpty><span [innerHTML]="noDataMessage"></span></ng-template>
          </p>
        </div>
        <div class="govuk-grid-column-full govuk-grid-column-one-half-from-desktop">
          <h3 class="govuk-body-s govuk-!-font-weight-bold govuk-!-margin-bottom-2 govuk-!-margin-top-4">
            Comparison group
          </h3>
          <p
            data-testid="comparisongroup"
            class="govuk-!-margin-bottom-0 asc-tile-key-text"
            [ngClass]="{
              'asc-color-turquoise asc-tile-key-number': comparisonGroupHasValue,
              'govuk-body-s': !comparisonGroupHasValue
            }"
          >
            <ng-container *ngIf="comparisonGroupHasValue; else compEmpty">
              <ng-container [ngSwitch]="content.type">
                <ng-container *ngSwitchCase="metrics.pay">
                  {{ comparisonGroupValue | formatMoney }}
                </ng-container>
                <ng-container *ngSwitchCase="metrics.sickness">
                  {{ comparisonGroupValue }}
                </ng-container>
                <ng-container *ngSwitchDefault>{{ comparisonGroupValue | formatPercent }}</ng-container>
              </ng-container>
            </ng-container>
            <ng-template #compEmpty> We do not have enough data to show this comparison yet. </ng-template>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
