<div class="govuk-grid-row">
  <div class="govuk-grid-column-two-thirds-from-desktop">
    <h1 class="govuk-heading-xl">New password saved</h1>
    <p>You can use your new password to sign in.</p>

    <div class="govuk-!-margin-top-9">
      <a role="button" draggable="false" class="govuk-button" [routerLink]="['/login']">
        Go to sign in
      </a>
    </div>
  </div>
</div>
