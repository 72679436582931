<div class="govuk-grid-row" #content>
  <div class="govuk-grid-column-full">
    <h1 class="govuk-heading-l govuk-!-margin-bottom-0">
      {{ workplace.name }}
    </h1>

    <p class="govuk-!-font-size-16 govuk-!-margin-top-4 govuk-!-margin-bottom-0">
      Workplace ID: {{ workplace.nmdsId }}
    </p>
  </div>
</div>
