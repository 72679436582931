<div>
  <ng-container *ngIf="alert && alert.type !== 'pending'">
    <ng-container *ngIf="linkTextForAlert; else generic_alert">
      <app-inset-text
        data-testid="success_alert_with_link"
        [color]="alert.type"
        [closable]="false"
        [removeTopMargin]="true"
        [removeBottomMargin]="false"
        [linkTextForAlert]="linkTextForAlert"
        (alertLinkClicked)="alertLinkClicked($event)"
        [noFloatRight]="noFloatRight"
      >
        <span role="status">{{ alert.message }}</span>
      </app-inset-text>
    </ng-container>

    <ng-template #generic_alert>
      <app-inset-text
        data-testid="generic_alert"
        [color]="alert.type"
        [closable]="true"
        [removeBottomMargin]="true"
        (closed)="remove()"
      >
        <span role="status">{{ alert.message }}</span>
      </app-inset-text>
    </ng-template>
  </ng-container>
</div>
