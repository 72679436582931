<div class="govuk-grid-row">
  <div class="govuk-grid-column-one-half">
    <h2 class="govuk-heading-m govuk-!-margin-bottom-0 govuk-!-font-size-27">Workplace</h2>
  </div>
</div>
<ng-container *ngIf="!updateWorkplaceAlert && (showCQCDetailsBanner || showSharingPermissionsBanner)">
  <div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds-from-desktop" *ngIf="showCQCDetailsBanner">
      <app-inset-text [color]="'todo'" data-testid="check-cqc-details">
        <h3 class="govuk-heading-s">You need to check your CQC details</h3>
        <p>
          Some of your details in the Adult Social Care Workforce Data Set (ASC-WDS) are different to those that the
          Care Quality <br />
          Commission (CQC) have.
        </p>
        <p>
          <a href="https://www.cqc.org.uk/location/{{ locationId }}" target="_blank">Please check your CQC details</a>
          and make sure they match your <br />ASC-WDS details.
        </p>
      </app-inset-text>
    </div>
    <div class="govuk-grid-column-two-thirds-from-desktop" *ngIf="showSharingPermissionsBanner">
      <app-inset-text [color]="'todo'" data-testid="check-sharing-permissions">
        <h3 class="govuk-heading-s">You need to review your data sharing permissions</h3>
        <p>Skills for Care have updated the list of data that will be shared with local authorities.</p>
        <p>You need to let us know that you're happy for us to share your data.</p>
        <p>
          <a (click)="navigateToShareDataPage($event)" href="#">Please review your data sharing permissions</a>
        </p>
      </app-inset-text>
    </div>
  </div>
</ng-container>

<ng-container>
  <app-inset-text *ngIf="updateWorkplaceAlert" color="todo">
    <a [routerLink]="['/workplace', workplace?.uid, 'start']" [state]="{ navigatedFromFragment: 'workplace' }">
      Start to add more details about your workplace
    </a>
  </app-inset-text>
  <app-workplace-summary [workplace]="workplace" [workerCount]="workerCount" [return]="summaryReturnUrl">
  </app-workplace-summary>
</ng-container>
