<app-error-summary *ngIf="serverError" [serverError]="serverError"> </app-error-summary>
<div class="govuk-form-group">
  <fieldset class="govuk-fieldset">
    <legend class="govuk-fieldset__legend govuk-fieldset__legend--l">
      <h1 id="dialogHeading" class="govuk-fieldset__heading">
        Your request to become a parent organisation is pending
      </h1>
    </legend>
  </fieldset>
</div>
<p>
  <strong>Your request will need to be approved.</strong>
</p>

<button type="button" (click)="closeDialogWindow(null)" class="govuk-button">close</button>
<button
  type="button"
  (click)="cancelRequestToBecomeAParent()"
  class="govuk-button govuk-button--secondary govuk-util__float-right"
>
  Cancel request
</button>
