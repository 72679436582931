<h2 class="govuk-heading-m">Training and qualifications</h2>
<dl class="govuk-summary-list asc-summarylist-border-top govuk-summary-list--wide-key govuk-!-margin-bottom-0">
  <div
    class="govuk-summary-list__row"
    [ngClass]="{
      'govuk-summary-list__row--no-bottom-border':
        (wdfView && worker.wdf?.careCertificate?.isEligible === Eligibility.NO) || showWdfConfirmations.careCertificate
    }"
  >
    <dt class="govuk-summary-list__key">Care Certificate</dt>

    <dd class="govuk-summary-list__value">
      {{ worker.careCertificate || '-' }}
    </dd>
    <dd *ngIf="canEditWorker" class="govuk-summary-list__actions">
      <app-summary-record-change
        *ngIf="!showWdfConfirmations.careCertificate"
        [explanationText]="' care certificate'"
        [link]="getRoutePath('care-certificate', wdfView)"
        [hasData]="!!worker.careCertificate"
        (setReturnClicked)="this.setReturn()"
      ></app-summary-record-change>
    </dd>
  </div>
  <app-wdf-warning-message
    *ngIf="wdfView && worker.wdf?.careCertificate?.isEligible === Eligibility.NO"
    [overallWdfEligibility]="overallWdfEligibility"
    data-testid="careCertificateWdfWarning"
  ></app-wdf-warning-message>
  <app-wdf-field-confirmation
    *ngIf="showWdfConfirmations.careCertificate"
    [changeLink]="getRoutePath('care-certificate', wdfView)"
    (fieldConfirmation)="this.confirmField('careCertificate')"
    (setReturnClicked)="this.setReturn()"
    [workerUid]="worker.uid"
    [staffRecordView]="true"
  ></app-wdf-field-confirmation>

  <div class="govuk-summary-list__row">
    <dt class="govuk-summary-list__key">Level 2 Adult Social Care Certificate</dt>

    <dd class="govuk-summary-list__value">
      {{ worker.level2CareCertificate?.value || '-' }}
    </dd>
    <dd *ngIf="canEditWorker" class="govuk-summary-list__actions">
      <app-summary-record-change
        [explanationText]="' level 2 adult social care certificate'"
        [link]="getRoutePath('level-2-care-certificate', wdfView)"
        [hasData]="!!worker.level2CareCertificate?.value"
        (setReturnClicked)="this.setReturn()"
      ></app-summary-record-change>
    </dd>
  </div>

  <div class="govuk-summary-list__row">
    <dt class="govuk-summary-list__key">Apprenticeship training</dt>
    <dd class="govuk-summary-list__value">
      {{ worker.apprenticeshipTraining || '-' | closedEndedAnswer }}
    </dd>
    <dd *ngIf="canEditWorker" class="govuk-summary-list__actions">
      <app-summary-record-change
        [explanationText]="' apprenticeship training'"
        [link]="getRoutePath('apprenticeship-training', wdfView)"
        [hasData]="!!worker.apprenticeshipTraining"
        (setReturnClicked)="this.setReturn()"
      ></app-summary-record-change>
    </dd>
  </div>

  <div
    class="govuk-summary-list__row"
    [ngClass]="{
      'govuk-summary-list__row--no-bottom-border':
        (wdfView && worker.wdf?.qualificationInSocialCare?.isEligible === Eligibility.NO) ||
        showWdfConfirmations.qualificationInSocialCare
    }"
  >
    <dt class="govuk-summary-list__key">Has a social care qualification</dt>
    <dd class="govuk-summary-list__value">
      {{ worker.qualificationInSocialCare || '-' | closedEndedAnswer }}
    </dd>
    <dd *ngIf="canEditWorker" class="govuk-summary-list__actions">
      <app-summary-record-change
        *ngIf="!showWdfConfirmations.qualificationInSocialCare"
        [explanationText]="' relevant social care qualification'"
        [link]="getRoutePath('social-care-qualification', wdfView)"
        [hasData]="!!worker.qualificationInSocialCare"
        (setReturnClicked)="this.setReturn()"
      ></app-summary-record-change>
    </dd>
  </div>
  <app-wdf-warning-message
    *ngIf="wdfView && worker.wdf?.qualificationInSocialCare?.isEligible === Eligibility.NO"
    [overallWdfEligibility]="overallWdfEligibility"
    data-testid="qualificationInSocialCareWdfWarning"
  ></app-wdf-warning-message>
  <app-wdf-field-confirmation
    *ngIf="showWdfConfirmations.qualificationInSocialCare"
    [changeLink]="getRoutePath('social-care-qualification', wdfView)"
    (fieldConfirmation)="this.confirmField('qualificationInSocialCare')"
    (setReturnClicked)="this.setReturn()"
    [workerUid]="worker.uid"
    [staffRecordView]="true"
  ></app-wdf-field-confirmation>

  <ng-container *ngIf="displaySocialCareQualifications">
    <div
      class="govuk-summary-list__row"
      [ngClass]="{
        'govuk-summary-list__row--no-bottom-border':
          (wdfView && worker.wdf?.socialCareQualification?.isEligible === Eligibility.NO) ||
          showWdfConfirmations.socialCareQualification
      }"
    >
      <dt class="govuk-summary-list__key">Highest level of social care qualification</dt>
      <dd class="govuk-summary-list__value">
        {{ worker.socialCareQualification?.title | closedEndedAnswer }}
      </dd>
      <dd *ngIf="canEditWorker" class="govuk-summary-list__actions">
        <app-summary-record-change
          *ngIf="!showWdfConfirmations.socialCareQualification"
          [explanationText]="' social care qualification level'"
          [link]="getRoutePath('social-care-qualification-level', wdfView)"
          [hasData]="!!worker.socialCareQualification"
          (setReturnClicked)="this.setReturn()"
        ></app-summary-record-change>
      </dd>
    </div>
    <app-wdf-warning-message
      *ngIf="wdfView && worker.wdf?.socialCareQualification?.isEligible === Eligibility.NO"
      [overallWdfEligibility]="overallWdfEligibility"
      data-testid="socialCareQualificationWdfWarning"
    ></app-wdf-warning-message>
    <app-wdf-field-confirmation
      *ngIf="showWdfConfirmations.socialCareQualification"
      [changeLink]="getRoutePath('social-care-qualification-level', wdfView)"
      (fieldConfirmation)="this.confirmField('socialCareQualification'); this.confirmField('qualificationInSocialCare')"
      (setReturnClicked)="this.setReturn()"
      [workerUid]="worker.uid"
      [staffRecordView]="true"
    ></app-wdf-field-confirmation>
  </ng-container>

  <div
    class="govuk-summary-list__row"
    [ngClass]="{
      'govuk-summary-list__row--no-bottom-border':
        (wdfView && worker.wdf?.otherQualification?.isEligible === Eligibility.NO) ||
        showWdfConfirmations.otherQualification
    }"
  >
    <dt class="govuk-summary-list__key">Other qualifications</dt>
    <dd class="govuk-summary-list__value">
      {{ worker.otherQualification || '-' | closedEndedAnswer }}
    </dd>
    <dd *ngIf="canEditWorker" class="govuk-summary-list__actions">
      <app-summary-record-change
        *ngIf="!showWdfConfirmations.otherQualification"
        [explanationText]="' other qualifications'"
        [link]="getRoutePath('other-qualifications', wdfView)"
        [hasData]="!!worker.otherQualification"
        (setReturnClicked)="this.setReturn()"
      ></app-summary-record-change>
    </dd>
  </div>
  <app-wdf-warning-message
    *ngIf="wdfView && worker.wdf?.otherQualification?.isEligible === Eligibility.NO"
    [overallWdfEligibility]="overallWdfEligibility"
    data-testid="otherQualificationWdfWarning"
  ></app-wdf-warning-message>
  <app-wdf-field-confirmation
    *ngIf="showWdfConfirmations.otherQualification"
    [changeLink]="getRoutePath('other-qualifications', wdfView)"
    (fieldConfirmation)="this.confirmField('otherQualification')"
    (setReturnClicked)="this.setReturn()"
    [workerUid]="worker.uid"
    [staffRecordView]="true"
  ></app-wdf-field-confirmation>

  <ng-container *ngIf="displayOtherQualifications">
    <div
      class="govuk-summary-list__row"
      [ngClass]="{
        'govuk-summary-list__row--no-bottom-border':
          (wdfView && worker.wdf?.highestQualification?.isEligible === Eligibility.NO) ||
          showWdfConfirmations.highestQualification
      }"
    >
      <dt class="govuk-summary-list__key">Highest level of other qualifications</dt>
      <dd class="govuk-summary-list__value">
        {{ worker.highestQualification?.title || '-' }}
      </dd>
      <dd *ngIf="canEditWorker" class="govuk-summary-list__actions">
        <app-summary-record-change
          *ngIf="!showWdfConfirmations.highestQualification"
          [explanationText]="' other qualification level'"
          [link]="getRoutePath('other-qualifications-level', wdfView)"
          [hasData]="!!worker.highestQualification"
          (setReturnClicked)="this.setReturn()"
        ></app-summary-record-change>
      </dd>
    </div>
    <app-wdf-warning-message
      *ngIf="wdfView && worker.wdf?.highestQualification?.isEligible === Eligibility.NO"
      [overallWdfEligibility]="overallWdfEligibility"
      data-testid="highestQualificationWdfWarning"
    ></app-wdf-warning-message>
    <app-wdf-field-confirmation
      *ngIf="showWdfConfirmations.highestQualification"
      [changeLink]="getRoutePath('other-qualifications-level', wdfView)"
      (fieldConfirmation)="this.confirmField('highestQualification'); this.confirmField('otherQualification')"
      (setReturnClicked)="this.setReturn()"
      [workerUid]="worker.uid"
      [staffRecordView]="true"
    ></app-wdf-field-confirmation>
  </ng-container>
</dl>
