<app-new-dashboard-header
  *ngIf="!isParentViewingSubsidiary"
  [tab]="'benchmarks'"
  [workplace]="workplace"
  [updatedDate]="tilesData?.meta.lastUpdated"
  data-html2canvas-ignore
  data-testid="dashboardHeader"
></app-new-dashboard-header>

<div class="govuk-width-container govuk-!-margin-top-7">
  <!-- <div id="benchmarks-tiles"> -->

  <app-new-comparison-group-header
    [canViewFullContent]="canViewFullBenchmarks"
    [meta]="tilesData?.meta"
    [workplaceID]="workplace.uid"
    (downloadPDF)="downloadAsPDF()"
  ></app-new-comparison-group-header>

  <div class="govuk-grid-row">
    <app-benchmark-tile
      [canViewFullContent]="canViewFullBenchmarks"
      [content]="payContent"
      [tile]="payTile"
      [workplaceID]="workplace.uid"
    >
    </app-benchmark-tile>
    <app-benchmark-tile
      [canViewFullContent]="canViewFullBenchmarks"
      [content]="turnoverContent"
      [tile]="turnoverTile"
      [workplaceID]="workplace.uid"
    >
    </app-benchmark-tile>
    <app-benchmark-tile
      [canViewFullContent]="canViewFullBenchmarks"
      [content]="sicknessContent"
      [tile]="sicknessTile"
      [workplaceID]="workplace.uid"
    >
    </app-benchmark-tile>
    <app-benchmark-tile
      [canViewFullContent]="canViewFullBenchmarks"
      [content]="qualificationsContent"
      [tile]="qualificationsTile"
      [workplaceID]="workplace.uid"
    >
    </app-benchmark-tile>
    <app-benchmarks-about-the-data
      #aboutData
      class="govuk-visually-hidden"
      [workplace]="workplace"
      [isParentViewingSubsidiary]=isParentViewingSubsidiary
    ></app-benchmarks-about-the-data>
  </div>
</div>
