<app-error-summary
  *ngIf="(form.invalid || serverError) && submitted"
  [formErrorsMap]="formErrorsMap"
  [serverError]="serverError"
  [form]="form"
>
</app-error-summary>

<div class="govuk-grid-row">
  <div class="govuk-grid-column-two-thirds-from-desktop">
    <h1 class="govuk-heading-xl">Welcome to the Adult Social Care Workforce Data Set</h1>

    <p>
      Before proceeding, you will need to check the box confirming you agree to the updated
      <a [routerLink]="['/terms-and-conditions']">
        terms and conditions<span class="govuk-visually-hidden">, (opens in a new window)</span>
      </a>
      of the service.
    </p>

    <p>You will be required to do this each time we update our terms and conditions.</p>

    <form (ngSubmit)="onSubmit()" [formGroup]="form" id="form" class="govuk-!-margin-top-6">
      <div class="govuk-form-group" [class.govuk-form-group--error]="submitted && form.invalid">
        <span *ngIf="submitted && form.invalid" id="termsAndConditions-error" class="govuk-error-message">
          <span class="govuk-visually-hidden">Error:</span>
          {{ getFirstErrorMessage('termsAndConditions') }}
        </span>
        <div class="govuk-checkboxes">
          <div class="govuk-checkboxes__item">
            <input
              class="govuk-checkboxes__input"
              [class.govuk-input--error]="submitted && form.invalid"
              id="termsAndConditions"
              name="termsAndConditions"
              type="checkbox"
              [formControlName]="'termsAndConditions'"
              required
            />
            <label class="govuk-label govuk-checkboxes__label" for="termsAndConditions">
              I agree to updated the terms and conditions of this service
            </label>
          </div>
        </div>
      </div>

      <div class="govuk-button-group">
        <button type="submit" class="govuk-button">
          Continue
        </button>
      </div>
    </form>
  </div>
</div>
