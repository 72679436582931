<h2 class="govuk-heading-m">{{ basicTitle }}</h2>
<dl
  class="govuk-summary-list govuk-summary-list--no-border asc-summarylist-border-top govuk-summary-list--wide-key govuk-!-margin-bottom-0"
  data-testid="name-and-contract-section"
>
  <div class="govuk-summary-list__row">
    <dt class="govuk-summary-list__key">Name or ID number</dt>
    <dd class="govuk-summary-list__value">
      {{ worker.nameOrId }}
    </dd>
    <dd *ngIf="canEditWorker" class="govuk-summary-list__actions">
      <app-summary-record-change
        [explanationText]="' basic record details'"
        [link]="
          mandatoryDetailsPage ? getMandatoryDetailsRoute('staff-details') : getRoutePath('staff-details', wdfView)
        "
        [hasData]="true"
      ></app-summary-record-change>
    </dd>
  </div>

  <div class="govuk-summary-list__row">
    <dt class="govuk-summary-list__key">Contract type</dt>
    <dd class="govuk-summary-list__value">
      <ng-container *ngIf="showPoolBankTag; else notPoolBankTag">Pool, Bank</ng-container>
      <ng-template #notPoolBankTag>
        {{ worker.contract }}
      </ng-template>
    </dd>
  </div>
  <app-wdf-field-confirmation
    *ngIf="showWdfConfirmations.contract"
    [changeLink]="getRoutePath('staff-details', wdfView)"
    (fieldConfirmation)="this.confirmField('contract')"
    [workerUid]="worker.uid"
    [staffRecordView]="true"
  ></app-wdf-field-confirmation>
</dl>

<dl class="govuk-summary-list asc-summarylist-border-top asc-summarylist-border-bottom govuk-summary-list--wide-key">
  <div
    class="govuk-summary-list__row"
    [ngClass]="{
      'govuk-summary-list__row--no-bottom-border': showWdfConfirmations.mainJob
    }"
    data-testid="main-job-role-section"
  >
    <dt class="govuk-summary-list__key">Main job role</dt>
    <dd class="govuk-summary-list__value">
      <ng-container *ngIf="worker.mainJob?.other; else title">
        <span>{{ worker.mainJob.other }}</span>
      </ng-container>
      <ng-template #title>
        <span>{{ worker.mainJob.title }}</span>
      </ng-template>
    </dd>
    <dd *ngIf="canEditWorker" class="govuk-summary-list__actions">
      <app-summary-record-change
        *ngIf="!showWdfConfirmations.mainJob"
        [explanationText]="' basic record details'"
        [link]="
          mandatoryDetailsPage ? getMandatoryDetailsRoute('main-job-role') : getRoutePath('main-job-role', wdfView)
        "
        [hasData]="true"
      ></app-summary-record-change>
    </dd>
  </div>
  <app-wdf-field-confirmation
    *ngIf="showWdfConfirmations.mainJob"
    [changeLink]="getRoutePath('main-job-role', wdfView)"
    (fieldConfirmation)="this.confirmField('mainJob')"
    [workerUid]="worker.uid"
    [staffRecordView]="true"
  ></app-wdf-field-confirmation>
</dl>
