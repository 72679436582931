<header #content class="govuk-header" role="banner" data-module="header">
  <div class="govuk-header__container">
    <div>
      <img src="/assets/images/logo.png" alt="Skills for Care" />
    </div>
    <div>
      <a class="govuk-header__strapline">Adult Social Care Workforce Data Set</a>
    </div>
  </div>
</header>
