<h2 class="govuk-heading-m">Employment details</h2>
<dl class="govuk-summary-list asc-summarylist-border-top govuk-summary-list--wide-key">
  <div class="govuk-summary-list__row" *ngIf="displayHealthAndCareVisa" data-testid="health-and-care-visa-section">
    <dt class="govuk-summary-list__key">On a Health and Care Worker visa</dt>
    <dd class="govuk-summary-list__value">
      {{ worker.healthAndCareVisa | closedEndedAnswer }}
    </dd>
    <dd *ngIf="canEditWorker" class="govuk-summary-list__actions">
      <app-summary-record-change
        [explanationText]="' Health and Care Worker visa'"
        [link]="getRoutePath('health-and-care-visa', wdfView)"
        [hasData]="!!worker.healthAndCareVisa"
        (setReturnClicked)="this.setReturn()"
      ></app-summary-record-change>
    </dd>
  </div>
  <div
    class="govuk-summary-list__row"
    *ngIf="displayEmployedFromOutsideOrInsideUk"
    data-testid="employed-inside-or-outside-section"
  >
    <dt class="govuk-summary-list__key">Employed from outside or inside the UK</dt>
    <dd class="govuk-summary-list__value">
      {{ displayEmployedFromOutsideOrInsideUkValue | closedEndedAnswer }}
    </dd>
    <dd *ngIf="canEditWorker" class="govuk-summary-list__actions">
      <app-summary-record-change
        [explanationText]="'Employed from outside or inside UK'"
        [link]="getRoutePath('inside-or-outside-of-uk', wdfView)"
        [hasData]="!!worker.employedFromOutsideUk"
        (setReturnClicked)="this.setReturn()"
      ></app-summary-record-change>
    </dd>
  </div>
  <div
    class="govuk-summary-list__row"
    [ngClass]="{
      'govuk-summary-list__row--no-bottom-border':
        (wdfView && worker.wdf?.mainJobStartDate?.isEligible === Eligibility.NO) ||
        showWdfConfirmations.mainJobStartDate
    }"
  >
    <dt class="govuk-summary-list__key">Date started in main job role</dt>
    <dd class="govuk-summary-list__value">
      {{ worker.mainJobStartDate ? mainStartDate : '-' }}
    </dd>
    <dd *ngIf="canEditWorker" class="govuk-summary-list__actions">
      <app-summary-record-change
        *ngIf="!showWdfConfirmations.mainJobStartDate"
        [explanationText]="' job start date'"
        [link]="getRoutePath('main-job-start-date', wdfView)"
        [hasData]="!!worker.mainJobStartDate"
        (setReturnClicked)="this.setReturn()"
      ></app-summary-record-change>
    </dd>
  </div>
  <app-wdf-warning-message
    *ngIf="wdfView && worker.wdf?.mainJobStartDate?.isEligible === Eligibility.NO"
    [overallWdfEligibility]="overallWdfEligibility"
    data-testid="mainJobStartDateWdfWarning"
  ></app-wdf-warning-message>
  <app-wdf-field-confirmation
    *ngIf="showWdfConfirmations.mainJobStartDate"
    [changeLink]="getRoutePath('main-job-start-date', wdfView)"
    (fieldConfirmation)="this.confirmField('mainJobStartDate')"
    (setReturnClicked)="this.setReturn()"
    [workerUid]="worker.uid"
    [staffRecordView]="true"
  ></app-wdf-field-confirmation>

  <div class="govuk-summary-list__row" *ngIf="displayMentalHealthProfessional">
    <dt class="govuk-summary-list__key">Approved Mental Health Professional</dt>
    <dd class="govuk-summary-list__value">
      {{ worker.approvedMentalHealthWorker | closedEndedAnswer }}
    </dd>
    <dd *ngIf="canEditWorker" class="govuk-summary-list__actions">
      <app-summary-record-change
        [explanationText]="' approved mental health professional'"
        [link]="getRoutePath('mental-health-professional', wdfView)"
        [hasData]="!!worker.approvedMentalHealthWorker"
        (setReturnClicked)="this.setReturn()"
      ></app-summary-record-change>
    </dd>
  </div>

  <div class="govuk-summary-list__row" *ngIf="displayNursingQuestions">
    <dt class="govuk-summary-list__key">Nursing and Midwifery Council category</dt>
    <dd class="govuk-summary-list__value">
      {{ (worker.registeredNurse | nursingCategoriesText) || '-' }}
    </dd>
    <dd *ngIf="canEditWorker" class="govuk-summary-list__actions">
      <app-summary-record-change
        [explanationText]="' nursing and midwifery council category'"
        [link]="getRoutePath('nursing-category', wdfView)"
        [hasData]="!!worker.registeredNurse"
        (setReturnClicked)="this.setReturn()"
      ></app-summary-record-change>
    </dd>
  </div>

  <div class="govuk-summary-list__row" *ngIf="displayNursingQuestions">
    <dt class="govuk-summary-list__key">Nursing specialisms used in current role</dt>
    <dd class="govuk-summary-list__value">
      <ng-container *ngIf="!worker.nurseSpecialisms"> - </ng-container>
      <ng-container *ngIf="worker.nurseSpecialisms?.value === 'No'"> Not using any of their specialisms </ng-container>
      <ng-container *ngIf="worker.nurseSpecialisms?.value === 'Don\'t know'">Not known</ng-container>
      <ng-container *ngIf="worker.nurseSpecialisms?.value === 'Yes'">
        <ul class="govuk-list govuk-!-margin-bottom-0">
          <li *ngFor="let specialism of worker.nurseSpecialisms.specialisms">
            <span>{{ specialism.specialism | nursingSpecialismsText }}</span>
          </li>
        </ul>
      </ng-container>
    </dd>
    <dd *ngIf="canEditWorker" class="govuk-summary-list__actions">
      <app-summary-record-change
        [explanationText]="' nursing and midwifery council category'"
        [link]="getRoutePath('nursing-specialism', wdfView)"
        [hasData]="!!worker.nurseSpecialisms"
        (setReturnClicked)="this.setReturn()"
      ></app-summary-record-change>
    </dd>
  </div>

  <div
    class="govuk-summary-list__row"
    [ngClass]="{
      'govuk-summary-list__row--no-bottom-border': wdfView && worker.wdf?.recruitedFrom?.isEligible === Eligibility.NO
    }"
  >
    <dt class="govuk-summary-list__key">Recruited from</dt>
    <dd class="govuk-summary-list__value">
      {{
        worker.recruitedFrom?.from?.from !== null
          ? worker.recruitedFrom?.from?.from
          : (worker.recruitedFrom?.value | openEndedAnswer)
      }}
    </dd>
    <dd *ngIf="canEditWorker" class="govuk-summary-list__actions">
      <app-summary-record-change
        [explanationText]="' recruited from'"
        [link]="getRoutePath('recruited-from', wdfView)"
        [hasData]="!!worker.recruitedFrom"
        (setReturnClicked)="this.setReturn()"
      ></app-summary-record-change>
    </dd>
  </div>
  <app-wdf-warning-message
    *ngIf="wdfView && worker.wdf?.recruitedFrom?.isEligible === Eligibility.NO"
    [overallWdfEligibility]="overallWdfEligibility"
    data-testid="recruitedFromWdfWarning"
  ></app-wdf-warning-message>

  <div class="govuk-summary-list__row">
    <dt class="govuk-summary-list__key">Year started in adult social care</dt>
    <dd class="govuk-summary-list__value">
      {{
        worker.socialCareStartDate?.year
          ? worker.socialCareStartDate?.year
          : (worker.socialCareStartDate?.value | openEndedAnswer)
      }}
    </dd>
    <dd *ngIf="canEditWorker" class="govuk-summary-list__actions">
      <app-summary-record-change
        [explanationText]="' adult social care date'"
        [link]="getRoutePath('adult-social-care-started', wdfView)"
        [hasData]="!!worker.socialCareStartDate"
        (setReturnClicked)="this.setReturn()"
      ></app-summary-record-change>
    </dd>
  </div>

  <ng-container *ngIf="displayDaysSickness">
    <div
      class="govuk-summary-list__row"
      [ngClass]="{
        'govuk-summary-list__row--no-bottom-border':
          (wdfView && worker.wdf?.daysSick?.isEligible === Eligibility.NO) || showWdfConfirmations.daysSick
      }"
    >
      <dt class="govuk-summary-list__key">Days sickness in last 12 months</dt>
      <dd class="govuk-summary-list__value">
        {{ worker.daysSick | workerDays | openEndedAnswer }}
      </dd>
      <dd *ngIf="canEditWorker" class="govuk-summary-list__actions">
        <app-summary-record-change
          *ngIf="!showWdfConfirmations.daysSick"
          [explanationText]="' days of sickness'"
          [link]="getRoutePath('days-of-sickness', wdfView)"
          [hasData]="!!worker.daysSick"
          (setReturnClicked)="this.setReturn()"
        ></app-summary-record-change>
      </dd>
    </div>
    <app-wdf-warning-message
      *ngIf="wdfView && worker.wdf?.daysSick?.isEligible === Eligibility.NO"
      [overallWdfEligibility]="overallWdfEligibility"
      data-testid="daysSickWdfWarning"
    ></app-wdf-warning-message>
    <app-wdf-field-confirmation
      *ngIf="showWdfConfirmations.daysSick"
      [changeLink]="getRoutePath('days-of-sickness', wdfView)"
      (fieldConfirmation)="this.confirmField('daysSick')"
      (setReturnClicked)="this.setReturn()"
      [workerUid]="worker.uid"
      [staffRecordView]="true"
    ></app-wdf-field-confirmation>
  </ng-container>

  <div
    class="govuk-summary-list__row"
    [ngClass]="{
      'govuk-summary-list__row--no-bottom-border':
        (wdfView && worker.wdf?.zeroHoursContract?.isEligible === Eligibility.NO) ||
        showWdfConfirmations.zeroHoursContract
    }"
  >
    <dt class="govuk-summary-list__key">Zero hours contract</dt>
    <dd class="govuk-summary-list__value">
      {{ worker.zeroHoursContract || '-' | closedEndedAnswer }}
    </dd>
    <dd *ngIf="canEditWorker" class="govuk-summary-list__actions">
      <app-summary-record-change
        *ngIf="!showWdfConfirmations.zeroHoursContract"
        [explanationText]="' contract with zero hours'"
        [link]="getRoutePath('contract-with-zero-hours', wdfView)"
        [hasData]="!!worker.zeroHoursContract"
        (setReturnClicked)="this.setReturn()"
      ></app-summary-record-change>
    </dd>
  </div>
  <app-wdf-warning-message
    *ngIf="wdfView && worker.wdf?.zeroHoursContract?.isEligible === Eligibility.NO"
    [overallWdfEligibility]="overallWdfEligibility"
    data-testid="zeroHoursContractWdfWarning"
  ></app-wdf-warning-message>
  <app-wdf-field-confirmation
    *ngIf="showWdfConfirmations.zeroHoursContract"
    [changeLink]="getRoutePath('contract-with-zero-hours', wdfView)"
    (fieldConfirmation)="this.confirmField('zeroHoursContract')"
    (setReturnClicked)="this.setReturn()"
    [workerUid]="worker.uid"
    [staffRecordView]="true"
  ></app-wdf-field-confirmation>

  <ng-container *ngIf="displayAverageWeeklyHours">
    <div
      class="govuk-summary-list__row"
      [ngClass]="{
        'govuk-summary-list__row--no-bottom-border':
          (wdfView && worker.wdf?.weeklyHoursAverage?.isEligible === Eligibility.NO) ||
          showWdfConfirmations.weeklyHoursAverage
      }"
    >
      <dt class="govuk-summary-list__key">Average weekly working hours</dt>
      <dd class="govuk-summary-list__value">
        {{
          isNumber(worker.weeklyHoursAverage?.hours)
            ? worker.weeklyHoursAverage?.hours
            : (worker.weeklyHoursAverage?.value | openEndedAnswer)
        }}
      </dd>
      <dd *ngIf="canEditWorker" class="govuk-summary-list__actions">
        <app-summary-record-change
          *ngIf="!showWdfConfirmations.weeklyHoursAverage"
          [explanationText]="' average weekly working hours'"
          [link]="getRoutePath('average-weekly-hours', wdfView)"
          [hasData]="
            !(
              worker.weeklyHoursAverage === null ||
              (worker.weeklyHoursAverage?.value === null && worker.weeklyHoursAverage?.hours === null)
            )
          "
          (setReturnClicked)="this.setReturn()"
        ></app-summary-record-change>
      </dd>
    </div>
    <app-wdf-warning-message
      *ngIf="wdfView && worker.wdf?.weeklyHoursAverage?.isEligible === Eligibility.NO"
      [overallWdfEligibility]="overallWdfEligibility"
      data-testid="weeklyHoursAverageWdfWarning"
    ></app-wdf-warning-message>
    <app-wdf-field-confirmation
      *ngIf="showWdfConfirmations.weeklyHoursAverage"
      [changeLink]="getRoutePath('average-weekly-hours', wdfView)"
      (fieldConfirmation)="this.confirmField('weeklyHoursAverage')"
      (setReturnClicked)="this.setReturn()"
      [workerUid]="worker.uid"
      [staffRecordView]="true"
    ></app-wdf-field-confirmation>
  </ng-container>

  <ng-container *ngIf="displayWeeklyContractedHours">
    <div
      class="govuk-summary-list__row"
      [ngClass]="{
        'govuk-summary-list__row--no-bottom-border':
          (wdfView && worker.wdf?.weeklyHoursContracted?.isEligible === Eligibility.NO) ||
          showWdfConfirmations.weeklyHoursContracted
      }"
    >
      <dt class="govuk-summary-list__key">Contracted weekly hours</dt>
      <dd class="govuk-summary-list__value">
        {{
          isNumber(worker.weeklyHoursContracted?.hours)
            ? worker.weeklyHoursContracted?.hours
            : (worker.weeklyHoursContracted?.value | openEndedAnswer)
        }}
      </dd>
      <dd *ngIf="canEditWorker" class="govuk-summary-list__actions">
        <app-summary-record-change
          *ngIf="!showWdfConfirmations.weeklyHoursContracted"
          [explanationText]="' contracted weekly hours'"
          [link]="getRoutePath('weekly-contracted-hours', wdfView)"
          [hasData]="
            !(
              worker.weeklyHoursContracted === null ||
              (worker.weeklyHoursContracted?.value === null && worker.weeklyHoursContracted?.hours === null)
            )
          "
          (setReturnClicked)="this.setReturn()"
        ></app-summary-record-change>
      </dd>
    </div>
    <app-wdf-warning-message
      *ngIf="wdfView && worker.wdf?.weeklyHoursContracted?.isEligible === Eligibility.NO"
      [overallWdfEligibility]="overallWdfEligibility"
      data-testid="weeklyHoursAverageWdfWarning"
    ></app-wdf-warning-message>
    <app-wdf-field-confirmation
      *ngIf="showWdfConfirmations.weeklyHoursContracted"
      [changeLink]="getRoutePath('weekly-contracted-hours', wdfView)"
      (fieldConfirmation)="this.confirmField('weeklyHoursContracted')"
      (setReturnClicked)="this.setReturn()"
      [workerUid]="worker.uid"
      [staffRecordView]="true"
    ></app-wdf-field-confirmation>
  </ng-container>

  <div
    class="govuk-summary-list__row"
    [ngClass]="{
      'govuk-summary-list__row--no-bottom-border':
        (wdfView && worker.wdf?.annualHourlyPay?.isEligible === Eligibility.NO) || showWdfConfirmations.annualHourlyPay
    }"
  >
    <dt class="govuk-summary-list__key">Hourly pay or annual salary</dt>

    <dd class="govuk-summary-list__value">
      {{ worker.annualHourlyPay | workerPay | closedEndedAnswer }}
    </dd>
    <dd *ngIf="canEditWorker" class="govuk-summary-list__actions">
      <app-summary-record-change
        *ngIf="!showWdfConfirmations.annualHourlyPay"
        [explanationText]="' salary'"
        [link]="getRoutePath('salary', wdfView)"
        [hasData]="!!worker.annualHourlyPay"
        (setReturnClicked)="this.setReturn()"
      ></app-summary-record-change>
    </dd>
  </div>
  <app-wdf-warning-message
    *ngIf="wdfView && worker.wdf?.annualHourlyPay?.isEligible === Eligibility.NO"
    [overallWdfEligibility]="overallWdfEligibility"
    data-testid="annualHourlyPayWdfWarning"
  ></app-wdf-warning-message>
  <app-wdf-field-confirmation
    *ngIf="showWdfConfirmations.annualHourlyPay"
    [changeLink]="getRoutePath('salary', wdfView)"
    (fieldConfirmation)="this.confirmField('annualHourlyPay')"
    (setReturnClicked)="this.setReturn()"
    [workerUid]="worker.uid"
    [staffRecordView]="true"
  ></app-wdf-field-confirmation>
</dl>
