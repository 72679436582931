<div class="govuk-grid-row">
  <div class="govuk-grid-column-two-thirds">
    <div class="govuk-!-margin-bottom-4">
      <h2 class="govuk-heading-m govuk-!-margin-bottom-0 govuk-!-font-size-27">
        Training and qualifications ({{ totalRecords || 0 }})
      </h2>
      <p *ngIf="tAndQsLastUpdated" class="govuk-!-font-size-16 govuk-!-margin-top-2">
        Last update, {{ tAndQsLastUpdated | date: 'd MMMM yyyy' }}
      </p>
    </div>
  </div>
  <div *ngIf="workers && workers.length" class="govuk-grid-column-one-third govuk-!-padding-right-0 govuk">
    <div class="govuk-!-margin-bottom-4">
      <ng-container *ngIf="totalRecords">
        <a
          class="govuk-list govuk-list--inline govuk-list govuk__flex govuk__align-items-center"
          role="button"
          draggable="false"
          href="/download/{{ now | date: 'yyyy-MM-dd' }}-training-report.xls"
          (click)="downloadTrainingReport($event)"
        >
          <img alt="" src="/assets/images/icon-download.svg" /><span class="govuk-!-margin-left-1">
            Download training report
          </span>
        </a>
      </ng-container>
      <ng-container *ngIf="isParent">
        <a
          class="govuk-list govuk-list--inline govuk-list govuk__flex govuk__align-items-center"
          role="button"
          draggable="false"
          href="/download/{{ now | date: 'yyyy-MM-dd' }}-parent-training-report.xls"
          (click)="downloadParentTrainingReport($event)"
        >
          <img alt="" src="/assets/images/icon-download.svg" /><span class="govuk-!-margin-left-1">
            Download parent training report
          </span>
        </a>
      </ng-container>
    </div>
  </div>
</div>

<div *ngIf="workers && workers.length" class="govuk-grid-row">
  <div class="govuk-grid-column-two-thirds">
    <ng-container *ngIf="canEditEstablishment">
      <div class="govuk-!-margin-bottom-4">
        <a class="govuk-list" [routerLink]="['/workplace', workplace.uid, 'add-and-manage-mandatory-training']"
          >Add and manage mandatory training categories
        </a>
      </div>
      <div>
        <a class="govuk-list" [routerLink]="['/workplace', workplace.uid, 'change-expires-soon-alerts']"
          >Manage expiry alerts
        </a>
      </div>
    </ng-container>
  </div>
  <div class="govuk-grid-column-one-third">
    <a
      *ngIf="canEditWorker"
      role="button"
      href="#"
      class="govuk-button govuk-util__float-right govuk-!-margin-top-7 govuk-!-margin-bottom-6"
      [routerLink]="['/workplace', workplace.uid, 'add-multiple-training', 'select-staff']"
      data-cy="add-multiple-training-records-button"
    >
      Add multiple training records
    </a>
  </div>
</div>
