<ng-container *ngIf="articleList?.length > 0">
  <h3 class="govuk-heading-s govuk-!-margin-top-1">ASC-WDS news</h3>
  <hr class="govuk-section-break--m govuk-section-break--visible" />
  <ul class="govuk-list govuk-!-margin-bottom-6">
    <li
      class="govuk-!-margin-bottom-3"
      [ngClass]="{ 'govuk-!-font-weight-bold': article.slug === currentArticleSlug }"
      *ngFor="let article of articleList"
    >
      <div class="govuk-grid-row">
        <div class="govuk-grid-column-three-quarters-from-desktop" style="width: 85%">
          <a [routerLink]="['/articles', article.slug]">{{ article.title }}</a>
        </div>

        <div class="govuk-grid-column-one-quarter-from-desktop govuk-!-padding-top-1" style="width: 15%">
          <img src="/assets/images/small_blue_arrow.svg" alt="" />
        </div>
      </div>

      <div class="govuk-grid-column-full"></div>
      <hr class="govuk-section-break--m govuk-section-break--visible" />
    </li>
  </ul>
</ng-container>
