<div class="govuk-!-margin-top-5 barchart-container">
  <div class="govuk-!-font-size-19 govuk-!-font-weight-bold govuk-!-margin-top-2">{{ section }}</div>
  <ng-container *ngIf="!rankingsData.stateMessage && rankingsData.hasValue; else noData">
    <ng-container *ngIf="isPay; else recruitmentAndRetention">
      <p class="govuk-body govuk-!-margin-top-2 govuk-!-margin-right-8" data-testid="all-pay-data">
        Your workplace pays more than
        <span class="govuk-!-font-weight-bold">{{ numberOfWorkplaces - rank }}</span> others in a comparision group of
        <span class="govuk-!-font-weight-bold">{{ numberOfWorkplaces }} </span> workplaces.
      </p>
    </ng-container>
    <ng-template #recruitmentAndRetention>
      <p class="govuk-body govuk-!-margin-top-2 govuk-!-margin-right-8" data-testid="all-recruitment-data">
        Your workplace has a higher {{ sectionInSummary | lowercase }} than
        <span class="govuk-!-font-weight-bold">{{ rank - 1 }}</span> others in a comparision group of
        <span class="govuk-!-font-weight-bold">{{ numberOfWorkplaces }} </span> workplaces.
      </p>
    </ng-template>
  </ng-container>

  <ng-template #noData>
    <ng-container *ngIf="rankingsData.stateMessage == 'no-comparison-data'; else hasComparisonData">
      <ng-container *ngIf="noWorkplaceData == false; else noWorkplaceAndComparisonData">
        <p class="govuk-body govuk-!-margin-top-2 govuk-!-margin-right-8" data-testid="no-comparison-data">
          We do not have enough comparison group data to<br />
          show you where you're positioned yet.
        </p>
      </ng-container>
      <ng-template #noWorkplaceAndComparisonData>
        <p class="govuk-body govuk-!-margin-top-2 govuk-!-margin-right-8" data-testid="no-workplace-or-comparison-data">
          We do not have enough workplace and comparison group <br />
          data to show where you're positioned yet.
        </p>
      </ng-template>
    </ng-container>
    <ng-template #hasComparisonData>
      <ng-container *ngIf="isPay; else recrAndRet">
        <p class="govuk-body govuk-!-margin-top-2 govuk-!-margin-right-8" data-testid="no-workplace-pay-data">
          You've not added any {{ section | lowercase }} data, so we <br />cannot show you where you're positioned.
        </p>
      </ng-container>
      <ng-template #recrAndRet>
        <ng-container *ngIf="type == 'vacancy'; else turnoverOrPerecent">
          <p class="govuk-body govuk-!-margin-top-2" data-testid="no-workplace-vacancy-data">
            You've not added any vacancies, so we cannot show<br />
            where you're positioned.
          </p>
        </ng-container>
        <ng-template #turnoverOrPerecent>
          <p class="govuk-body govuk-!-margin-top-2" data-testid="no-workplace-turnover-or-percent-data">
            You've not added the data we need to show where <br />you're positioned (see '<app-about-the-data-link
            ></app-about-the-data-link>').
          </p>
        </ng-template>
      </ng-template>
    </ng-template>
  </ng-template>
  <highcharts-chart class="chart" [Highcharts]="Highcharts" [options]="options"></highcharts-chart>
</div>
