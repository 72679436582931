<div class="govuk-grid-row">
  <div class="govuk-grid-column-one-half">
    <h2 class="govuk-heading-m govuk-!-font-size-27">Staff records ({{ workerCount || 0 }})</h2>
  </div>
  <div *ngIf="canAddWorker" class="govuk-grid-column-one-half govuk-util__align-right">
    <a
      role="button"
      draggable="false"
      class="govuk-button"
      [routerLink]="['/workplace', this.workplace.uid, 'staff-record', 'create-staff-record']"
      data-cy="add-staff-record-button"
    >
      Add a staff record
    </a>
  </div>
</div>

<app-status *ngIf="createStaffResponse" [title]="'&#10003; Success.'">
  {{
    createStaffResponse
      | i18nPlural
        : {
            '=1': '# staff record has been saved.',
            other: '# staff records have been saved.'
          }
  }}
</app-status>

<ng-container *ngIf="workers?.length > 0; else noStaffRecords">
  <app-total-staff-panel
    [workplace]="workplace"
    [totalStaff]="this.workplace.numberOfStaff"
    [totalWorkers]="workerCount"
    [returnToDash]="true"
    data-cy="total-staff-panel"
  ></app-total-staff-panel>

  <app-staff-summary
    *ngIf="workers"
    [workplace]="workplace"
    [workers]="workers"
    [workerCount]="workerCount"
    data-cy="staff-summary"
  ></app-staff-summary>
</ng-container>

<ng-template #noStaffRecords>
  <app-inset-text color="warning" [removeTopMargin]="true">Start to add your staff records</app-inset-text>

  <p>To add a staff record you need to know the person’s:</p>
  <ul class="govuk-list govuk-list--bullet">
    <li>name or ID number</li>
    <li>main job role</li>
    <li>contract type</li>
  </ul>

  <p>
    Save this mandatory information and then continue to add more details so that you get a complete record for that
    person.
  </p>
  <p>You'll be asked about that person's:</p>
  <ul class="govuk-list govuk-list--bullet">
    <li>personal details, like their date of birth and National Insurance number</li>
    <li>employment details, like when they started their main job role</li>
    <li>training and qualifications</li>
  </ul>
</ng-template>
