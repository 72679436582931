<app-new-dashboard-header tab="home" [workplace]="workplace"></app-new-dashboard-header>
<div class="govuk-width-container govuk-!-margin-top-7">
  <ng-container *ngIf="isParentApprovedBannerViewed === false; else standardAlert">
    <app-alert
      data-testid="parentApprovedBanner"
      [linkTextForAlert]="'What you can do as a parent workplace'"
      (notifyAlertLinkClicked)="goToAboutParentsLink($event)"
      [noFloatRight]="true"
    ></app-alert>
  </ng-container>
  <ng-template #standardAlert>
    <app-alert></app-alert>
  </ng-template>
</div>
<app-summary-section
  [workplace]="workplace"
  [workerCount]="workerCount"
  [workersCreatedDate]="workersCreatedDate"
  [trainingCounts]="trainingCounts"
  [workersNotCompleted]="workersNotCompleted"
  [(navigateToTab)]="navigateToTab"
  [canViewListOfWorkers]="canViewListOfWorkers"
  [canViewEstablishment]="canViewEstablishment"
  [showMissingCqcMessage]="showMissingCqcMessage"
  [workplacesCount]="workplacesCount"
  [noOfWorkersWhoRequireInternationalRecruitment]="noOfWorkersWhoRequireInternationalRecruitment"
></app-summary-section>
<div class="govuk-width-container govuk-!-margin-top-7">
  <div class="govuk-grid-row">
    <div class="govuk-grid-column-full govuk-!-padding-left-2 govuk-!-padding-right-2">
      <div class="govuk-grid-column-one-third asc-card-padding">
        <app-card [image]="'/assets/images/bulk-upload.svg'">
          <ng-container *ngIf="canBulkUpload; else noLink">
            <a
              class="govuk-link--no-visited-state govuk-!-font-size-19 govuk-!-font-weight-bold"
              routerLink="/bulk-upload"
              href="#"
              data-testid="bulkUploadLink"
              >Bulk upload your data</a
            >
          </ng-container>
          <ng-template #noLink>
            <p class="govuk-!-font-size-19 govuk-!-font-weight-bold">Bulk upload your data</p>
          </ng-template>
          <p class="govuk-!-margin-top-5">
            Add all your workplace data into ASC-WDS without the need to add it manually.
          </p></app-card
        >
      </div>

      <div *ngIf="canViewReports" class="govuk-grid-column-one-third asc-card-padding">
        <app-card [image]="'/assets/images/wdf.svg'">
          <a
            class="govuk-link--no-visited-state govuk-!-font-size-19 govuk-!-font-weight-bold"
            routerLink="/wdf"
            href="#"
            >Does your data meet funding requirements?</a
          >
          <p class="govuk-!-margin-top-5">
            Update your data so that you can claim money back against the costs of training your staff.
          </p></app-card
        >
      </div>

      <div class="govuk-grid-column-one-third asc-card-padding">
        <app-card [image]="'/assets/images/benchmarks.svg'">
          <a
            (click)="navigateToTab($event, 'benchmarks')"
            class="govuk-link--no-visited-state govuk-!-font-size-19 govuk-!-font-weight-bold"
            href="#"
          >
            <ng-container *ngIf="bigThreeServices && hasBenchmarkComparisonData; else notBigThreeServices">
              See how your pay, recruitment and retention compares against other workplaces</ng-container
            >
            <ng-template #notBigThreeServices> See how you compare against other workplaces</ng-template>
          </a>
          <p class="govuk-!-margin-top-5">
            {{ benchmarksMessage }}
          </p>
        </app-card>
      </div>
    </div>
  </div>
  <div class="govuk-grid-row">
    <div class="govuk-grid-column-full govuk-!-padding-left-2 govuk-!-padding-right-2">
      <div class="govuk-grid-column-one-third asc-card-padding">
        <app-card [image]="'/assets/images/benefits-bundle.svg'">
          <a
            class="govuk-link--no-visited-state govuk-!-font-size-19 govuk-!-font-weight-bold"
            routerLink="/benefits-bundle"
            href="#"
            >View the ASC-WDS Benefits Bundle</a
          >
          <p class="govuk-!-margin-top-5">
            Get discounts and special offers from endorsed training providers and from across Skills for Care's product
            range.
          </p></app-card
        >
      </div>
      <div class="govuk-grid-column-one-third asc-card-padding">
        <app-card [image]="'/assets/images/certificate.svg'">
          <a
            class="govuk-link--no-visited-state govuk-!-font-size-19 govuk-!-font-weight-bold"
            routerLink="/asc-wds-certificate"
            href="#"
            >Get your ASC-WDS certificate</a
          >
          <p class="govuk-!-margin-top-5">
            Get a certificate, email signature or logo to show that you provide information to help the adult social
            care sector.
          </p></app-card
        >
      </div>
      <div *ngIf="article" class="govuk-grid-column-one-third asc-card-padding">
        <app-card [image]="'/assets/images/news.svg'">
          <a
            class="govuk-link--no-visited-state govuk-!-font-size-19 govuk-!-font-weight-bold"
            [routerLink]="['/articles', article.slug]"
            href="#"
            >ASC-WDS news</a
          >
          <p class="govuk-!-margin-top-5">
            Read all about how your data gets used and be the first to hear about any new ASC-WDS developments.
          </p></app-card
        >
      </div>
    </div>
  </div>

  <div *ngIf="canRunLocalAuthorityReport && workplace" data-cy="download-report" class="govuk-grid-row">
    <div class="govuk-grid-column-full">
      <div class="asc-link-box">
        <a
          class="govuk__flex govuk__align-items-center"
          href="/download/{{ now | date: 'yyyy-MM-dd' }}-sfc-local-authority-report.xls'"
          (click)="downloadLocalAuthorityReport($event)"
        >
          <img alt="" src="/assets/images/icon-download.svg" />
          <span class="govuk-!-margin-left-1">Download local authority progress report (XLS)</span>
        </a>
      </div>
    </div>
  </div>
</div>
