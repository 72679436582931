<dl
  *ngIf="summaryList"
  class="govuk-summary-list govuk-!-margin-bottom-0"
  [class.govuk-summary-list--no-border]="topBorder || wrapBorder"
  [class.govuk-summary-list--top-border]="topBorder"
  [class.govuk-summary-list--wrap-border]="wrapBorder"
>
  <div class="govuk-summary-list__row" *ngFor="let item of summaryList">
    <dt class="govuk-summary-list__key">
      {{ item.label }}
    </dt>
    <ng-container *ngIf="item.label === 'Password' && displayShowPasswordToggle; else nonPassword">
      <dd class="govuk-summary-list__value">
        <span class="asc-password-wrap">{{ showPassword ? item.data : '******' }}</span>
        <a
          class="govuk-link govuk-link--no-visited-state govuk-!-margin-left-6"
          href="#"
          role="button"
          (click)="togglePassword($event)"
          ><ng-container *ngIf="showPassword; else showText">Hide</ng-container
          ><ng-template #showText>Show</ng-template> password</a
        >
      </dd>
    </ng-container>
    <ng-template #nonPassword>
      <dd class="govuk-summary-list__value asc__preline">
        {{ item.data }}
      </dd>
    </ng-template>
    <ng-container *ngIf="item.route">
      <dd *ngIf="canNavigate === undefined ? true : canNavigate" class="govuk-summary-list__actions">
        <a [routerLink]="item.route.url" (click)="emitSetReturn()">
          Change
          <span class="govuk-visually-hidden"> {{ item.label.toLowerCase() }}</span>
        </a>
      </dd>
    </ng-container>
  </div>
</dl>
