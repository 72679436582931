<footer role="contentinfo">
  <div class="govuk-width-container">
    <span class="footer-tagline">In partnership with</span>

    <div class="govuk-footer__meta">
      <div class="govuk-footer__meta-item">
        <div class="department-of-health-logo">
          <div class="department-of-health-logo__crest">
            <span class="department-of-health-logo__name">Department<br />of Health &amp;<br />Social Care</span>
          </div>
        </div>
      </div>

      <div class="govuk-footer__meta-item govuk-footer__meta-item--grow">
        <ul class="govuk-list govuk-list--inline govuk-!-font-size-16">
          <li><a [routerLink]="['/cookie-policy']" class="govuk-footer__link">Cookie policy</a></li>
          <li><a [routerLink]="['/contact-us']" class="govuk-footer__link">Contact us</a></li>
          <li>
            <a [routerLink]="['/accessibility-statement']" class="govuk-footer__link">Accessibility statement</a>
          </li>
          <li>
            <a [routerLink]="['/terms-and-conditions']" class="govuk-footer__link">Terms and conditions</a>
          </li>
          <li>
            <a [routerLink]="['/privacy-notice']" class="govuk-footer__link">Privacy notice</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
