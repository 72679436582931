<app-new-dashboard-header
  [tab]="'workplace'"
  [workplace]="workplace"
  [updatedDate]="workplace.updated"
></app-new-dashboard-header>
<div class="govuk-width-container govuk-!-padding-top-2">
  <app-alert></app-alert>
  <ng-container *ngIf="canEditEstablishment && addWorkplaceDetailsBanner">
    <app-inset-text [color]="'todo'">
      <div class="govuk-grid-row">
        <div class="govuk-grid-column-full">
          <a [routerLink]="['/workplace', workplace?.uid, 'start']">Start to add more details about your workplace</a>
        </div>
      </div>
    </app-inset-text>
  </ng-container>
  <ng-container *ngIf="canEditEstablishment && showCqcDetailsBanner">
    <app-check-cqc-details data-testid="check-cqc-details-banner"></app-check-cqc-details>
  </ng-container>

  <app-new-workplace-summary
    [workplace]="workplace"
    [workerCount]="workerCount"
    [return]="summaryReturnUrl"
    [(navigateToTab)]="navigateToTab"
    data-testid="workplace-summary"
  ></app-new-workplace-summary>
</div>
