<app-error-summary
  *ngIf="submitted && form.invalid"
  [formErrorsMap]="formErrorsMap"
  [form]="form"
  [serverError]="serverError"
  data-testid="deleteWorkplaceErrorSummary"
>
</app-error-summary>

<div class="govuk-grid-row govuk-!-padding-bottom-1 govuk-!-margin-top-1">
  <div class="govuk-grid-column-two-thirds">
    <span class="govuk-caption-xl" data-testid="workplaceNameCaption">{{ this.workplace.name }}</span>
    <h1 class="govuk-heading-l">Delete workplace</h1>
    <div style="width: 84%">
      <form #formEl novalidate (ngSubmit)="onSubmit($event)" [formGroup]="form" autocomplete="off" aria-label="form">
        <div
          class="govuk-form-group"
          [class.govuk-form-group--error]="submitted && form.get('deleteWorkplace').invalid"
        >
          <fieldset class="govuk-fieldset">
            <legend class="govuk-fieldset__legend govuk-fieldset__legend--m">
              <h2 class="govuk-fieldset__heading govuk-!-margin-top-1" data-testid="workplaceNameQuestion">
                Are you sure you want to delete this workplace ({{ this.workplace.name }})?
              </h2>
            </legend>

            <p class="govuk-!-margin-top-1">
              <span class="govuk-body govuk-!-font-weight-bold">This action cannot be undone.</span> It will permanently
              delete this workplace and all of its staff records.
            </p>

            <span
              *ngIf="submitted && form.get('deleteWorkplace').errors"
              id="deleteWorkplace-error"
              class="govuk-error-message"
            >
              <span class="govuk-visually-hidden">Error:</span>
              {{ getFormErrorMessage('deleteWorkplace', 'required') }}
            </span>
            <div class="govuk-radios" data-module="govuk-radios">
              <div class="govuk-radios__item">
                <input
                  class="govuk-radios__input"
                  [class.govuk-input--error]="submitted && form.get('deleteWorkplace').invalid"
                  id="deleteWorkplace-1"
                  name="deleteWorkplace"
                  [formControlName]="'deleteWorkplace'"
                  type="radio"
                  value="yes"
                />
                <label class="govuk-label govuk-radios__label" for="deleteWorkplace-1">
                  Yes, delete this workplace
                </label>
              </div>
              <div class="govuk-radios__item">
                <input
                  class="govuk-radios__input"
                  [class.govuk-input--error]="submitted && form.get('deleteWorkplace').invalid"
                  id="deleteWorkplace-2"
                  name="deleteWorkplace"
                  [formControlName]="'deleteWorkplace'"
                  type="radio"
                  value="no"
                />
                <label class="govuk-label govuk-radios__label" for="deleteWorkplace-2"> No, keep this workplace </label>
              </div>
            </div>
          </fieldset>

          <div class="govuk-!-margin-top-8 govuk-button-group">
            <button
              type="submit"
              class="govuk-button govuk-button--warning govuk-!-margin-right-9"
              data-module="govuk-button"
            >
              Continue
            </button>
            <a href="#" class="govuk-button govuk-button--link govuk-!-margin-left-9" [routerLink]="['/dashboard']"
              >Cancel</a
            >
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
