<h1 id="dialogHeading">
  Become a parent organisation
</h1>
<p>Skills for Care will review your request and you will get a notification to say if your request was successful.</p>
<p>Skills for Care will contact you if they need more information about your workplace.</p>

<button type="button" (click)="sendRequestToBecomeAParent()" class="govuk-button">Send request</button>
<a
  href="#"
  class="govuk-list govuk-list--inline govuk-util__float-right govuk-!-margin-top-2"
  (click)="closeDialogWindow($event, null)"
  >Cancel</a
>
