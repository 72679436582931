<div
  class="asc-summary-box"
  data-testid="summary-box"
  *ngIf="totalExpiredTraining || totalExpiringTraining || totalStaffMissingMandatoryTraining"
>
  <div class="asc-container">
    <div class="asc-content" *ngIf="totalExpiredTraining" data-testid="expired-column">
      <div class="asc-content--image">
        <img src="/assets/images/flag-red.svg" alt="" />
      </div>
      <div>
        <a
          class="govuk-link govuk-link--no-visited-state"
          [routerLink]="['/workplace', workplaceUid, 'training-and-qualifications', 'expired-training']"
          >{{ totalExpiredTraining }}
          <ng-container [ngPlural]="totalExpiredTraining">
            <ng-template ngPluralCase="=1"> record has expired </ng-template>
            <ng-template ngPluralCase="other"> records have expired </ng-template>
          </ng-container>
        </a>
      </div>
    </div>
    <hr *ngIf="totalExpiredTraining && (totalExpiringTraining || totalStaffMissingMandatoryTraining)" />
    <div class="asc-content" *ngIf="totalExpiringTraining" data-testid="expiring-column">
      <div class="asc-content--image">
        <img src="/assets/images/flag-orange.svg" alt="" />
      </div>
      <div>
        <a
          href="#"
          class="govuk-link govuk-link--no-visited-state"
          [routerLink]="['/workplace', workplaceUid, 'training-and-qualifications', 'expires-soon-training']"
        >
          {{ totalExpiringTraining }}
          <ng-container [ngPlural]="totalExpiringTraining">
            <ng-template ngPluralCase="=1"> record expires soon </ng-template>
            <ng-template ngPluralCase="other"> records expire soon </ng-template>
          </ng-container>
        </a>
      </div>
    </div>
    <hr *ngIf="totalExpiringTraining && totalStaffMissingMandatoryTraining" />
    <div class="asc-content" *ngIf="totalStaffMissingMandatoryTraining" data-testid="missing-column">
      <div class="asc-content--image">
        <img src="/assets/images/flag-red.svg" alt="" />
      </div>
      <div>
        <a
          href="#"
          class="govuk-link govuk-link--no-visited-state"
          [routerLink]="['/workplace', workplaceUid, 'training-and-qualifications', 'missing-mandatory-training']"
        >
          {{ totalStaffMissingMandatoryTraining }}
          <ng-container [ngPlural]="totalStaffMissingMandatoryTraining">
            <ng-template ngPluralCase="=1"> staff is missing mandatory training </ng-template>
            <ng-template ngPluralCase="other"> staff are missing mandatory training </ng-template>
          </ng-container>
        </a>
      </div>
    </div>
  </div>
</div>
