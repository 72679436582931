<app-error-summary
  *ngIf="submitted && form.invalid"
  [formErrorsMap]="formErrorsMap"
  [form]="form"
  [serverError]="serverError"
>
</app-error-summary>

<div class="govuk-grid-row">
  <div class="govuk-grid-column-two-thirds-from-desktop">
    <form #formEl novalidate (ngSubmit)="onSubmit()" [formGroup]="form" id="server-error">
      <fieldset class="govuk-fieldset">
        <legend class="govuk-fieldset__legend govuk-fieldset__legend--xl">
          <h1 class="govuk-fieldset__heading">Reset password</h1>
        </legend>

        <div [formGroupName]="'passwordGroup'">
          <div class="govuk-form-group" [class.govuk-form-group--error]="submitted && getPasswordInput.errors">
            <label class="govuk-label" for="createPasswordInput"> Password </label>
            <span id="createPasswordInput-hint" class="govuk-hint">
              Must be at least 8 characters long and have uppercase letters, lowercase letters, numbers and special
              characters like !, £.
            </span>
            <span *ngIf="submitted" id="createPasswordInput-error" class="govuk-error-message">
              <span class="govuk-visually-hidden">Error:</span>
              <span *ngIf="getPasswordInput.hasError('required')">
                {{ getFormErrorMessage('createPasswordInput', 'required') }}
              </span>
              <span *ngIf="getPasswordInput.hasError('pattern')">
                {{ getFormErrorMessage('createPasswordInput', 'pattern') }}
              </span>
            </span>
            <input
              aria-describedby="createPasswordInput-hint"
              class="govuk-input"
              [class.govuk-input--error]="submitted && getPasswordInput.errors"
              [formControlName]="'createPasswordInput'"
              id="createPasswordInput"
              name="createPasswordInput"
              type="password"
            />
          </div>

          <div
            class="govuk-form-group govuk-!-margin-bottom-8"
            [class.govuk-form-group--error]="submitted && getConfirmPasswordInput.errors"
          >
            <label class="govuk-label" for="confirmPasswordInput"> Confirm password </label>
            <span *ngIf="submitted" id="confirmPasswordInput-error" class="govuk-error-message">
              <span class="govuk-visually-hidden">Error:</span>
              <span
                *ngIf="getConfirmPasswordInput.hasError('required') || getConfirmPasswordInput.hasError('notMatched')"
                id="passwordGroup-error"
              >
                {{ getFormErrorMessage('confirmPasswordInput', 'required') }}
              </span>
            </span>
            <input
              class="govuk-input"
              [class.govuk-input--error]="submitted && getConfirmPasswordInput.errors"
              [formControlName]="'confirmPasswordInput'"
              id="confirmPasswordInput"
              name="confirmPasswordInput"
              type="password"
            />
          </div>
        </div>
      </fieldset>

      <button type="submit" class="govuk-button">Save new password</button>
    </form>

    <div class="govuk-grid-row">
      <div class="govuk-grid-column-full govuk-!-margin-bottom-6">
        <a [routerLink]="['/login']">Back to sign in</a>
      </div>
    </div>
  </div>
</div>
