<div>
  <table class="govuk-table govuk-!-margin-top-2">
    <thead class="govuk-table__head">
      <tr class="govuk-table__row">
        <th scope="col" class="govuk-table__header"></th>
        <th scope="col" class="govuk-table__header comparison-table-header govuk-!-text-align-centre">
          Your workplace
        </th>
        <th scope="col" class="govuk-table__header comparison-table-header govuk-!-text-align-centre">
          Comparison group
        </th>
      </tr>
    </thead>
    <tbody class="govuk-table__body">
      <tr class="govuk-table__row first-row" data-testid="vacancyRow">
        <th scope="row" class="govuk-table__header comparison-row-header govuk-!-font-weight-regular">Vacancy rate</th>
        <td class="govuk-table__cell workplace-cell">{{ vacancyWorkplaceData }}</td>
        <td class="govuk-table__cell comparison-group-cell">{{ vacancyComparisonGroupData }}</td>
      </tr>
      <tr class="govuk-table__row" data-testid="turnoverRow">
        <th scope="row" class="govuk-table__header comparison-row-header govuk-!-font-weight-regular">Turnover rate</th>
        <td class="govuk-table__cell workplace-cell">{{ turnoverWorkplaceData }}</td>
        <td class="govuk-table__cell comparison-group-cell">{{ turnoverComparisonGroupData }}</td>
      </tr>
      <tr class="govuk-table__row" data-testid="timeInRoleRow">
        <th scope="row" class="govuk-table__header govuk-!-font-weight-regular">
          Percentage of staff still <br />in their main job role after <br />12 months
        </th>
        <td class="govuk-table__cell workplace-cell">{{ timeInRoleWorkplaceData }}</td>
        <td class="govuk-table__cell comparison-group-cell">{{ timeInRoleComparisonGroupData }}</td>
      </tr>
    </tbody>
  </table>

  <div class="govuk-width-container govuk-!-margin-top-7">
    <app-benchmarks-select-view-panel
      [falseSelectionName]="'Where you rank'"
      [trueSelectionName]="'Where you\'re positioned'"
      (handleViewToggle)="handleViewBenchmarkPosition($event)"
    >
    </app-benchmarks-select-view-panel>
    <ng-container *ngIf="viewBenchmarksPosition; else showRankings">
      <div class="govuk-!-margin-top-7" data-testid="barcharts">
        <app-data-area-barchart
          [section]="'Vacancy rate'"
          [type]="'vacancy'"
          [rankingsData]="vacancyRankings"
          [isPay]="false"
          [altDescription]="'Vacancy rate barchart'"
          [noWorkplaceData]="vacancyNoWorkplaceData"
        ></app-data-area-barchart>
        <app-data-area-barchart
          [section]="'Turnover rate'"
          [type]="'turnover'"
          [rankingsData]="turnoverRankings"
          [isPay]="false"
          [altDescription]="'Turnover rate barchart'"
          [noWorkplaceData]="turnoverNoWorkplaceData"
        ></app-data-area-barchart>
        <app-data-area-barchart
          [section]="'Percentage of staff still in their main job role after 12 months'"
          [type]="'timeInRole'"
          [rankingsData]="timeInRoleRankings"
          [isPay]="false"
          [altDescription]="'Time in role rate barchart'"
          [noWorkplaceData]="timeInRoleNoWorkplaceData"
        ></app-data-area-barchart>
      </div>
    </ng-container>
    <ng-template #showRankings>
      <div class="govuk-!-margin-top-7" data-testid="rankings">
        <app-data-area-ranking
          [rankingTitle]="'Vacancy rate'"
          [workplaceRankNumber]="vacancyCurrentRank"
          [workplacesNumber]="vacancyMaxRank"
          [noWorkplaceData]="vacancyNoWorkplaceData"
          [isPay]="false"
          [type]="'vacancy'"
        ></app-data-area-ranking>
        <app-data-area-ranking
          [rankingTitle]="'Turnover rate'"
          [workplaceRankNumber]="turnoverCurrentRank"
          [workplacesNumber]="turnoverMaxRank"
          [noWorkplaceData]="turnoverNoWorkplaceData"
          [isPay]="false"
          [type]="'turnover'"
        ></app-data-area-ranking>
        <app-data-area-ranking
          [rankingTitle]="'Percentage of staff still in their main job role after 12 months'"
          [workplaceRankNumber]="timeInRoleCurrentRank"
          [workplacesNumber]="timeInRoleMaxRank"
          [noWorkplaceData]="timeInRoleNoWorkplaceData"
          [isPay]="false"
          [type]="'timeInRole'"
        ></app-data-area-ranking>
      </div>
    </ng-template>
  </div>

  <app-data-area-useful-link-recruitment></app-data-area-useful-link-recruitment>
</div>
