<ng-container *ngIf="staffRecordView; else oldView">
  <div class="govuk-summary-list__row govuk-summary-list__row--no-bottom-border">
    <dt class="govuk-summary-list__key"></dt>
    <dd class="govuk-summary-list__value govuk-summary-list__message">
      <div class="govuk-button-group confirmation-content">
        <ng-container *ngIf="!confirmButtonClicked; else confirmed">
          <img
            alt="Red flag icon"
            src="/assets/images/red-flag-wdf-table.svg"
            class="govuk-!-margin-right-2 asc-icon-align"
          />
          <div class="govuk-!-margin-right-5">Is this still correct?</div>
          <button
            class="govuk-button govuk-button--primary govuk-!-margin-right-5 govuk-!-margin-bottom-0 govuk-!-padding-top-1 govuk-!-padding-bottom-1"
            data-module="govuk-button"
            (click)="confirmField()"
          >
            Yes, it is
          </button>
          <a class="govuk-link" [routerLink]="changeLink" (click)="setReturn()">No, change it</a>
        </ng-container>
        <ng-template #confirmed>
          <img class="govuk-!-margin-right-2 asc-icon-align" src="/assets/images/tick-icon.svg" alt="" />
          <div class="govuk-!-margin-right-5 govuk-!-margin-bottom-3">Meeting requirements</div>
        </ng-template>
      </div>
    </dd>
    <dd class="govuk-summary-list__value govuk-summary-list__message"></dd>
  </div>
  <div class="govuk-summary-list__row">
    <dt class="govuk-!-padding-top-2"></dt>
    <dd></dd>
    <dd></dd>
  </div>
</ng-container>
<ng-template #oldView>
  <div class="govuk-button-group govuk-!-margin-top-1 govuk-!-margin-bottom-0">
    <ng-container *ngIf="!confirmButtonClicked; else confirmed">
      <img class="govuk-!-margin-right-1 asc-icon-align" src="/assets/images/cross-icon.svg" alt="" />
      <div class="govuk-!-margin-right-5">Is this still correct?</div>
      <button
        class="govuk-button govuk-button--secondary govuk-!-margin-right-5"
        data-module="govuk-button"
        (click)="confirmField()"
      >
        Yes, it is
      </button>
      <a class="govuk-link" [routerLink]="changeLink" (click)="setReturn()">No, change it</a>
    </ng-container>
    <ng-template #confirmed>
      <img
        class="govuk-!-margin-top-1 govuk-!-margin-right-1 asc-icon-align"
        src="/assets/images/tick-icon.svg"
        alt=""
      />
      <div class="govuk-!-margin-right-5">Meeting requirements</div>
    </ng-template>
  </div>
</ng-template>
