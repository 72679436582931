<app-inset-text [color]="'todo'">
  <div class="govuk-grid-row">
    <div class="govuk-grid-column-full">
      <h3 class="govuk-heading-s">You need to check your CQC details</h3>
      <p>
        Some of your details in the Adult Social Care Workforce Data Set (ASC-WDS) are <br />different to those that the
        Care Quality Commission (CQC) have.
      </p>
      <p>
        <a href="https://www.cqc.org.uk/location/{{ locationId }}" target="_blank">Please check your CQC details</a>
        and make sure they match your ASC-WDS details.
      </p>
    </div>
  </div>
</app-inset-text>
